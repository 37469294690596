export default class {
    constructor(websocket) {
        this.i = 0;
        this.ws = websocket;
        this.callbacks = [];


        // this.register();
    }

    async getImage(
        cameraId,
        cb,
        timestamp = (Date.now() - 20000).toString(),
        onlyIFrame = true
    ) {
        try {
            const response = await this.ws.send({
                cameraId: cameraId,
                action: "image",
                timestamp: timestamp,
                timelineStart: 1,
                timelineEnd: onlyIFrame ? 20000000 : 5,
                timelineDensity: 20000000,
                // targetWidth: 10000,
                // quality: 100,
                // crop: {
                //     left: 0.3,
                //     top: 0.3,
                //     width: 0.2,
                //     height: 0.2,
                // },
            })
            cb(response.id, response.data)
        } catch (error){
            // if (error.type === this.ws.TIMEOUT)
                cb(error.requestId, null, error)

        }
    }
}

// import Logger from "@/utils/logger.js"
import { createStore } from 'vuex'

import cameraRepository from '../services/cameraRepository';
import packageJson from "../../package.json"
import newAPI from '../config/api'
import { API } from '../services'

import account from "./account"
import site from "./site"
import camera from "./camera"
import control from "./control"
import wsHandler from '../services/wsManager';
import {setLocale} from "yup"
import $router from '../router'

setLocale({
    mixed: {
        required: 'This is a required field!',
        default: 'Invalid data!',
    },
    string: {
        email: "Must be a valid email!",
        min: "Must be at least ${min} characters"
    },
    boolean: {
        isValue: "Must be accepted!"
    }
});

const ApplicationSettings = {}
const store = createStore({
    modules: {
        account,
        site,
        camera,
        control,
    },
    state: {
        API: newAPI,
        storage: JSON.parse(localStorage.getItem("token"))?localStorage:sessionStorage,
        controlTags: [],
        serverIP: null,
        websocket: null,
        wsManager: null,
        // cameras: [{"id":"GERGO_CAM_1","site":{"id":"GERGO","name":"Gergő","location":"Határ út"},"connection":{"id":"H1","ipAddress":"8aff081aa06b.sn.mynetname.net","httpPort":"","httpsPort":"","rtspPort":"8554","rtspPath":"","auth":{"login":"vidarex","password":"Vidarex2580"}},"vendor":"vendor","type":"type","name":"Határ út utca kép","capabilities":[null],"status":"ONLINE","macAddress":"","recordingLength":0},{"id":"GERGO_CAM_2","site":{"id":"GERGO","name":"Gergő","location":"Határ út"},"connection":{"id":"H2","ipAddress":"8aff081aa06b.sn.mynetname.net","httpPort":"","httpsPort":"","rtspPort":"8555","rtspPath":"","auth":{"login":"vidarex","password":"Vidarex2580"}},"vendor":"vendor","type":"type","name":"Határ út erkély","capabilities":[null],"status":"ONLINE","macAddress":"","recordingLength":0}],
        // cameras: [{"id":"PECSELY_CAM_1","site":{"id":"PECSELY","name":"Pécsely építkezés","location":"Pécsely"},"connection":{"id":"PECSELY1","ipAddress":"d5940da58c1a.sn.mynetname.net","httpPort":"","httpsPort":"","rtspPort":"5554","rtspPath":"","auth":{"login":"admin","password":"Pwd4pecsely%2F"}},"vendor":"Milesight","type":"type","name":"Pécsely építkezés","capabilities":[null],"status":"ONLINE","macAddress":"","recordingLength":0}],
        camRep: null,
        vvsHost: null,
        vvsTimeOffset: 0,
        streamID: null,
        events: [],
        eventsTimeOffset: 0,
        loading: false,
        activeCameraIndex: 0,
        deviceOrientation: 'portrait',
        version: packageJson.version,
        // adminMode: ApplicationSettings.getBoolean("adminMode") || false,
        currentCameraRoi: null,
        classes: [],
        selectedSearch: []
    },
    mutations: {
        SET_STORAGE(state, value){
            state.storage = value
        },
        setOrientation(state, newValue) {
            state.deviceOrientation = newValue
        },
        setAdminMode(state, newValue) {
            state.adminMode = newValue
            ApplicationSettings.setBoolean("adminMode", newValue)
        },
        // setWsManager(state, newValue) {
        //     state.wsManager = newValue
        // },
        setWebsocket(state, newValue) {
            state.camRep = new cameraRepository(newValue)
            state.websocket = newValue
        },
        SET_ACTIVE_CAMERA(state, newValue) {
            state.activeCameraIndex = newValue
        },
        SET_EVENTS(state, newValue) {
            state.events = newValue
        },
        SET_STREAM_ID(state, newValue) {
            state.streamID = newValue
        },
        SET_EVENTS_TIME_OFFSET(state, newValue) {
            state.eventsTimeOffset = newValue
        },
        SET_CAMERAS(state, newValue) {
            state.cameras = newValue
        },
        SET_SERVER(state, newValue) {
            state.serverIP = newValue
        },
        SET_LOGIN(state, newValue) {
            state.isLoading = newValue
        },
        SET_VVS_HOST(state, newValue) {
            state.vvsHost = newValue
        },
        SET_VVS_TIME_OFFSET(state, newValue) {
            state.vvsTimeOffset = newValue
        },
        SET_CURRENT_CAMERA_ROI(state, newValue) {
            state.currentCameraRoi = newValue
        },
        SET_CLASSES(state, newValue) {
            state.classes = newValue
        },
        SET_SELECTED_SEARCH(state, newValue) {
            state.selectedSearch = newValue
        }
    },
    actions: {
        isLoggedIn(){
            return this.getters["account/isLoggedIn"]
        },
        setStorage({commit}, value){
            commit("SET_STORAGE", value)
        },
        setLoading({commit}, value){
            commit("SET_LOGIN", value)
        },
        logout(){
            $router.push('/');
            this.dispatch('closeWebsocket');
            this.dispatch("account/logout")
            this.dispatch("camera/reset")
        },
        // async getCameraImage({state, getters}, {camera, options}){
        //     Logger.log("stojre getimage start", options)
        //     return new Promise((resolve, reject) => {
        //         Logger.log("promise start")

        //         getters
        //         //state.camRep.getImage(getters.activeCamera.id, (id, data)=>{
        //             state.camRep.getImage(camera.id, (id, data, error)=>{
        //             Logger.log("getImage", id)
        //             if (error) return reject(error)
        //             if (!data || data.byteLength <=4) return reject()
        //             resolve(new Blob([data.slice(4)], {
        //                 type: "image/jpeg",
        //             }))
        //         }, Date.now()-20000, true)
        //     })
        // },
        async getEvents({commit}, {camId, timestamp}){
            if (camId !== "PETER_CAM_3") return commit("SET_EVENTS", [])
            const mapResult = await API.getCameraStreamId(camId)
            if (mapResult && mapResult.content && mapResult.content.result[0]){
                const {streamId, timeOffset} = mapResult.content.result[0]
                commit("SET_STREAM_ID", streamId)
                commit("SET_EVENTS_TIME_OFFSET",timeOffset + 1900 ?? 0)
                const result = await API.getCameraEvents(streamId, timestamp - 1000*60*60*2, timestamp)
                if (result && result.content && result.content.result)
                commit("SET_EVENTS",result.content.result)
                else return commit("SET_EVENTS", [])
            }
        },
        async getEventDetails(ctx, {streamID, ts}){
            const result = await API.getCameraEventDetails(streamID, ts)
            if (result && result.content && result.content.result)
            return result.content.result
        },
        // async getImage({state}, {camId, timestamp, timelineZoom}){

        //     return new Promise((resolve, reject) => {
        //         Logger.log("promise start")

        //         state.camRep.getImage(camId, (id, data, error)=>{
        //             Logger.log("getImage", id, data)
        //             if (error) return reject(error)
        //             if (!data || data.byteLength <=4) return reject({message: "Felvétel nem elérhető!"})
        //             resolve(new Blob([data.slice(4)], {
        //                 type: "image/jpeg",
        //             }))
        //         }, timestamp, timelineZoom>5)
        //     })
        // },
        async getDemoServer(){
            return API.getDemoServer()
        },
        // async login({commit}, data) {

        //     debugger; // eslint-disable-line no-debugger
        //     const userSitesResponse = await API.getUserSites(data.code)
        //     let cams = []

        //     if (userSitesResponse.statusCode !== 200)
        //         return userSitesResponse
        //     const userSites = userSitesResponse.content

        //     if (userSites.sites === "*"){
        //         (await API.getSites()).content.forEach(site => {
        //             cams.push(API.getSiteCameras(site.id))
        //         });
        //     } else {
        //         userSites.sites.split(",").forEach(site => {
        //             cams.push(API.getSiteCameras(site))
        //         });
        //         Logger.log(userSites.sites.split(","))
        //     }
        //     const tmp = await Promise.all(cams)
        //     cams = (tmp.reduce((tmp, x) => [...tmp, ...x.content], []).map(c => ({
        //         id: c.id,
        //         site: c.site,
        //         name: c.name,
        //     })))
        //     Logger.log("cameras", cams)
        //     commit('SET_CAMERAS', cams)
        //     return userSitesResponse
        // },
        createWebsocket({commit, getters}, data){
            commit('setWebsocket', wsHandler( data || "wss://" + getters.vvsRoot))
        },
        closeWebsocket({state}){
            if (state.websocket)
                state.websocket.close()
        },
        openWebsocket({state}){
            state?.websocket?.open?.()
        },
        restoreState({state}){
            state
            store.dispatch("account/restore")
            // const account = JSON.parse(state.storage.getItem("account")) ?? {}
            // const token = JSON.parse(state.storage.getItem("token"))
            // // commit("account/setToken", token);
            // // commit("account/setAccountData", account);
            // if (!store.state.websocket && token) {
            //     store.dispatch("createWebsocket");
            //     store.dispatch("openWebsocket");
            // }
        }
    },
    getters: {
        cameras(state){
            return state.camera.cameras
        },
        filteredCameras(state){
            if (state.camera.tags.length === 0)
            return state.camera.cameras
            const filtered = state.camera.cameras.filter(camera => {
                return state.camera.tags.map(tag => tag.value.name).every( tag => camera.tags.map(tag => tag.name).includes(tag))// || camera.site.map(site => site.name.defaultText).includes(state.camera.tags[0])
            })
            return filtered
        },
        vvsRoot(state){
            state
            return ""//state?.account?.token?.vvs.replace('vvs-hu.vidarex.com:9999', 'vvs-hu.vidarex.com:8999').replace("80.77.123.20:9999", "vvs2.vidarex.com:8999")
        },
        vvs(state){
            return state.vvsHost //state?.account?.token?.vvs.replace('vvs-hu.vidarex.com:9999', 'vvs-hu.vidarex.com:8999').replace("80.77.123.20:9999", "vvs2.vidarex.com:8999") + "/vvs/services/rest/v1.0"
        },
        activeCamera(state){
            return state.cameras[state.activeCameraIndex]
        },
        getSelectedSearch(state){
            return state.selectedSearch
        },
    }
});

store.dispatch("restoreState")

export default store

<template>
    <input
        tabindex="-1"
        type="checkbox"
        :class="infoType"
        :id="$uuid"
        ref="input"
        class="input"
        @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label class="container" :class="infoType" :for="$uuid">
        <div tabindex="0" class="box" @keyup="toggle">
            <svg
                class="icon"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21 9L11.375 18L7 13.9091"
                    stroke="#00359A"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
        <slot>
            <span class="label">{{ label }}</span>
        </slot>
    </label>
    <span class="info" :class="infoType">{{ info }}</span>
</template>

<script>
export default {
    name: "VCheckbox",
    props: {
        modelValue: Boolean,
        label: String,
        info: String,
        infoType: {
            default: "info",
            type: String,
        },
    },
    mounted() {
        this.$refs.input.checked = this.modelValue;
    },
    methods: {
        toggle(event) {
            if (event.which === 32) this.$refs.input.checked = !this.$refs.input.checked;
        },
    },
};
</script>

<style scoped>
.input {
    position: absolute;
    opacity: 0;
}
.container {
    display: inline-block;
}
.icon {
    visibility: hidden;
}
.input:checked + .container .icon {
    visibility: visible;
}
.box {
    border-radius: 4px;
    vertical-align: middle;
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #f4f4f4;
    border: 1px solid #c1cbcc;
}
.label {
    margin-left: 11px;
}
.info {
    display: block;
    margin-top: 4px;
}
.info.error {
    color: #ff6565;
}
.input.error {
    background-color: #ff6565;
    border-color: #ff6565;
    color: white;
    margin-bottom: 4px;
}
.container.error .box {
    background-color: #ff6565;
}
</style>

<template>
  <div class="complete-range">
    <template v-if="Array.isArray(getSelectedRangeStyle())">
      <div
        v-for="(segment, index) in getSelectedRangeStyle()"
        :key="index"
        class="time-block lightblue"
        :style="segment"
      ></div>
    </template>
    <template v-else>
      <div
        class="time-block lightblue"
        :style="getSelectedRangeStyle()"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TimeRange",
  props: {
    completeRange: Number,
    selectedStartHour: Number || null,
    selectedEndHour: Number || null,
  },
  computed: {
    getSelectedStartMinutes(){
      return Number(this.selectedStartHour.split(':')[0]) * 60 + Number(this.selectedStartHour.split(':')[1])
    },
    getSelectedEndMinutes(){
      return Number(this.selectedEndHour.split(':')[0]) * 60 + Number(this.selectedEndHour.split(':')[1])
    }
  },
  methods: {
    // isMinuteInSelectedRange(minute) {
    //   const startMinutes = this.getSelectedStartMinutes;
    //   const endMinutes = this.getSelectedEndMinutes;

    //   // Handle the case where the selected range spans midnight (e.g., 22:00 to 02:00)
    //   if (startMinutes > endMinutes) {
    //     return minute >= startMinutes || minute <= endMinutes;
    //   }

    //   return minute >= startMinutes && minute <= endMinutes;
    // },
    getSelectedRangeStyle() {
  const startMinutes = this.getSelectedStartMinutes;
  const endMinutes = this.getSelectedEndMinutes;

  if (startMinutes === null || endMinutes === null){
    return {
      left: `0%`,
      width: `0%`,
    };
  }
  if (startMinutes === endMinutes) {
    return {
      left: `0%`,
      width: `100%`,
    };
  } else if (startMinutes > endMinutes) {
  const leftPercentage1 = 0;
  const widthPercentage1 = (endMinutes / this.completeRange) * 100;

  const leftPercentage2 = (startMinutes / this.completeRange) * 100;
  const widthPercentage2 = (this.completeRange - startMinutes) / this.completeRange * 100;

  return [
    {
      left: `${leftPercentage1}%`,
      width: `${widthPercentage1}%`,
    },
    {
      left: `${leftPercentage2}%`,
      width: `${widthPercentage2}%`,
    },
  ];
} else {
    const leftPercentage = (startMinutes / this.completeRange) * 100;
    const widthPercentage = ((endMinutes - startMinutes) / this.completeRange) * 100;

    return {
      left: `${leftPercentage}%`,
      width: `${widthPercentage}%`,
    };
  }
},
    },
  }
</script>
<style scoped>
.complete-range {
  width: 90px;
  height: 20px;
  position: relative;
  background-color: #222;
  border: solid grey 1px;
}
  .time-block {
    position: absolute;
    height: 20px;
  }

  .lightblue {
    background-color: #00A0B6;
  }

</style>
import { createApp } from "vue";
import i18n from "./i18n";
import App from "./pages/App.vue";
import "normalize.css";
import "@/theme/base.scss";

import uuid from "@/plugins/uuid";
import LogRocket from "logrocket";
import { VueReCaptcha } from "vue-recaptcha-v3";

import { plugin as ComponentLibrary } from "@vidarex-vsaas/client-component-library";
// import wasm from "@vidarex-vsaas/wasm-decoder/decoder/dist/ffmpeg.min.wasm";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("tgd4lk/player-production");
}

import store from "./store";
import router from "./router";

const app = createApp(App);

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});
app.use(ComponentLibrary, {
  
});
app.use(uuid);
app.use(store);
app.use(router);
app.use(i18n);
app.mount("#app");

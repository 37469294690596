<template>
  <div class="page">
    <LoadingOverlay />
    <div class="stock">
      <img class="logo" src="@/assets/logo/main.svg" alt="Vidarex" />
    </div>
    <div class="login-box">
      <Form
        @submit="onSubmit"
        @invalidSubmit="onInvalidSubmit"
        :validation-schema="validationSchema"
        ref="form"
        v-slot="{ errors }"
      >
        <h1 class="header">{{ $t("changePassword.forgotPw") }}</h1>
        <h2 class="subhead">
          {{ $t("changePassword.recoveryText") }}
        </h2>
        <Field name="email" v-slot="{ handleChange, value, errorMessage }">
          <Input
            :modelValue="value"
            @update:modelValue="handleChange"
            type="email"
            :label="$t('changePassword.emailAddress')"
            :info="errorMessage"
            :infoType="errorMessage && 'error'"
          />
        </Field>
        <div>
          <span class="info error">{{ errors.other }}</span>
          <Button :text="$t('changePassword.resetPw')" type="submit" />
        </div>
        <div>
          <Button
            theme="secondary"
            :text="$t('changePassword.backToLogin')"
            @click="$router.push('login')"
          />
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Logger from "@/utils/logger.js";
import Button from "@/components/Button.vue";
import Input from "@/components/Input.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { Field, Form } from "vee-validate";
import { mapActions, mapState } from "vuex";

export default {
  name: "VResetPassword",
  components: {
    Button,
    Input,
    LoadingOverlay,
    Field,
    Form,
  },
  computed: {
    ...mapState("account", {
      validationSchema: "resetPasswordValidationSchema",
    }),
  },
  data() {
    return {
      otherError: "",
    };
  },
  async mounted() {},
  methods: {
    ...mapActions("account", {
      resetPasswordSendMail: "resetPasswordSendMail",
    }),
    async onSubmit(data) {
      this.$store.dispatch("setLoading", true);

      try {
        await this.resetPasswordSendMail({ ...data })
          .then(() => {
            this.$router.push({ name: "ResetEnd" });
          })
          .catch(this.$refs.form.setErrors)
          .finally(() => this.$store.dispatch("setLoading", false));
      } catch (error) {
        Logger.log(error);
        this.$store.dispatch("setLoading", false);
      }
    },
    onInvalidSubmit(event) {
      //TODO: set focus on the first error
      Logger.log(event.errors);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.logo {
  position: fixed;
  margin: 50px;
  display: none;
  @include for-tablet-up {
    display: block;
  }
}

.separator {
  height: 1px;
  border: 0;
  background: #1f211c;
  opacity: 0.1;
  margin: 40px 0;
}

.page {
  display: flex;
  height: 100%;
  background-color: white;
}

.stock {
  background-image: url("../assets/images/promos/login_bg_2.jpg");
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  @include for-tablet-up {
    width: 4 * $col;
  }
}
.content-box,
.login-box {
  width: 10 * $col;
  @include for-tablet-up {
    width: 6 * $col;
  }
  @include for-desktop-up {
    width: 4 * $col;
  }
  height: 80%;
  max-width: 500px;
  margin: auto;
}

.header {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.subheader {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.forgot_link {
  display: block;
  text-align: right;
}
.info.error {
  display: block;
  color: #ff6565;
  margin: 20px 0 0 0;
}
</style>


// import { WEBSOCKET_STATES } from '@/services/websocketCommon.js'
import { getControlRooms, getControlRoomsId } from "../../services/apiv2"

const initialState = ()=>({
    tags: [],
    controlRooms: [],
    currentView: null,
})

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setTags(state, data){
            state.tags = data
        },
        setControlRooms(state, data){
            state.controlRooms = data
        },
        setCurrent(state, data){
            state.currentView = data
        },
        resetState(state) {
            Object.assign(state, initialState());
        },
    },
    getters: {
        // filteredCameras(){
        //     return this.cameras
        // }
    },
    actions: {
        reset({commit}){
            commit('resetState')
        },
        async getControlRooms ({commit}) {
            commit("setControlRooms", await getControlRooms())
        },
        async getControlRoomsId ({commit},id) {
            commit("setCurrent", await getControlRoomsId(id))
        },
    }
}

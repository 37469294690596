<template>
  <div class="page">
    <LoadingOverlay />
    <div class="stock">
      <img class="logo" src="@/assets/logo/main.svg" alt="Vidarex" />
    </div>
    <div class="content-box">
      <h1 class="header">{{ $t("register.end.thankYou") }}</h1>
      <p>{{ $t("register.end.sentConfirm") }}</p>
      <Button
        theme="secondary"
        :text="$t('register.form.backToLogin')"
        @click="$router.push('login')"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
export default {
  name: "VRegistrationEnd",
  components: {
    Button,
  },
  data() {
    return {
      code:
        JSON.parse(this.$store.state.storage.getItem("account"))?.email ?? "", // FIXME
      error: "",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      tos: false,
    };
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.logo {
  position: fixed;
  margin: 50px;
  display: none;
  @include for-tablet-up {
    display: block;
  }
}

.separator {
  height: 1px;
  border: 0;
  background: #1f211c;
  opacity: 0.1;
  margin: 40px 0;
}

.page {
  display: flex;
  height: 100%;
  background-color: white;
}

.stock {
  background-image: url("../assets/images/promos/login_bg_2.jpg");
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  @include for-tablet-up {
    width: 4 * $col;
  }
}
.content-box,
.login-box {
  width: 10 * $col;
  @include for-tablet-up {
    width: 6 * $col;
  }
  @include for-desktop-up {
    width: 4 * $col;
  }
  height: 80%;
  max-width: 500px;
  margin: auto;
}

.header {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.subheader {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.forgot_link {
  display: block;
  text-align: right;
}
</style>

<template>
    <div class="page" ref="page">
        <Header>
            <div class="header-toolbar" v-if="!hideMenuUI">
                <Button icon="grid" @click="routeToMatrix()" />
                <Button icon="fullscreen" @click="$refs.cv.toggleFullscreen()" />
              </div>
              <div style="margin: 0 1.5rem 0 4rem">
              <label v-if="hiddenFeatureEnabled" class="switch" @click.stop.prevent="roiButtonAction">
                    <input type="checkbox" class="roi-checkbox" :class="{ checked: roiToggle }">
                    <span class="slider round"></span>
                </label>
            </div>
              <div v-if="hiddenFeatureEnabled" class="search-box">
                <Search ref="search" :modelValue="analyticClipTags" @update:modelValue="searchBarValueUpdate" :suggestions="suggestions" :highlight="removeAge"/>
                <p v-show="noResults" class="no-results">No results found!</p>
              </div>
              <Button class="analytic-btn" :class="{active: analyticClipTags.length != 0 }" v-if="hiddenFeatureEnabled" style="height: 22px; position: relative; margin: 0 2rem 0 0.5rem; overflow: hidden; min-width: 22px;">
                <img v-if="hiddenFeatureEnabled" @click="toAnalyticClipsPage" src="../assets/icons/player/hamburgerMenu.svg" alt="Vidarex" />
              </Button>
              <div v-if="false" class="roi-container">
                <span class="roi-tooltip">{{ roiToggle ? 'Disable video analytics' : 'Activate video analytics' }}</span>
            </div>
            <Button @click="toggleBoundingBoxes()" class="add" :class="{'add--active': boundingBoxesEnabled}" v-if="hiddenFeatureEnabled" style="width:22px; height: 22px; position: relative; margin: 0 0.5rem 0 0.5rem; overflow: hidden; display: flex; align-items: center; justify-content: center; min-width: 22px;"><img src="@/assets/icons/player/bounding_box.svg"  alt="Toggle bounding boxes" /></Button>
              <Button class="add" v-if="hiddenFeatureEnabled" style="height: 22px; position: relative; margin: 0 2rem 0 0.5rem; overflow: hidden; min-width: 55px;">
                    Actions
                <select class="hidden-select" value="" @input="selectMenu">
                    <option disabled selected value>Menu</option>
                    <option value="set_notification">Set notification</option>
                    <option disabled value="power_bi">
                        Send data to Power BI
                    </option>
                    <option disabled value="3rd_party">
                        Send notification to 3rd-party system
                    </option>
                    <option value="notification_list">
                        List of notifications (admin)
                    </option>
                </select>
                <NotificationPanel :streamId="this.id" :toggle="this.notificationToggle" @close="notificationToggle = false"/>
            </Button>
        </Header>
        <div class="modal-overlay" v-show="searchModal.toggle || alertModal.toggle || ageModal.toggle" @click="searchModal.toggle = false; alertModal.toggle = false; ageModal.toggle = false"></div>
        <div class="video-player" v-show="isReady">
            <div class="modal-container" :class="{ active: searchModal.toggle && !isCurrentCameraBodyCam }">
                <span style="font-weight: 500; margin-bottom: 10px;">
                    AI-based Video Analytics Service 
                </span>
                <span>
                    By clicking the 'Turn On Analytics' button below, our AI-based video analytics service will begin analyzing incoming video content from the selected camera going forward; past video content will not be analyzed.

The search feature allows you to find persons or objects in specific regions of the image “on the fly.”  This exciting journey in video analytics begins with a 14-day free trial period. 

Depending on the selected analytics model, different objects, persons, their properties, or behaviors can be detected.

                </span>
                <div class="modal-container-model" >
                    <span style="font-weight: 500; margin-bottom: 10px;">Model selector</span>
                    <form action="">
                        <select style="margin-bottom: 20px;">
                            <option sected>Base analytics (Person, Vehicle, Animal)</option>
                            <option>Age, gender, mood detection</option>
                            <option>PPE detection (Hard hat, Visibility vest)</option>
                            <option>Construction machinery (10 different machine types)</option>
                            <option>License plate recognition</option>
                            <option>Custom trained model</option>
                        </select>
                    </form>
                    <div class="modal-btn-container">
                        <button @click="enableAccessVideoAnalytics">Turn On Analytics</button>
                        <button @click="denyAccessVideoAnalytics">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="modal-container" :class="{ active: alertModal.toggle && !isCurrentCameraBodyCam }">
                <span>Are you sure you want to turn off the video analytics service?</span>
                <div class="modal-btn-container">
                    <button @click="alertModal.toggle = false; roiToggle = false">Turn Off Analytics</button>
                    <button @click="alertModal.toggle = false">Cancel</button>
                </div>
            </div>
            <div class="age-container" :class="{ active: ageModal.toggle && !isCurrentCameraBodyCam }">
                <div class="range-container">
                    <div class="sliders-control">
                        <input id="fromSlider" type="range" v-model="fromSliderValue"  min="0" max="100" @input="controlAgeRangeInput"/>
                        <input id="toSlider" type="range" v-model="toSliderValue" min="0" max="100" @input="controlAgeRangeInput"/>
                    </div>
                    <div class="age-range-container">
                        <span>
                            From: {{ fromSliderValue }}
                        </span>
                        <span>
                            To: {{ toSliderValue }}
                        </span>
                    </div>
                    <div class="modal-btn-container">
                        <button @click="saveAnalyticAgeRange">Save</button>
                        <button @click="closeAnalyticAgeRange">Close</button>
                    </div>
            </div>
            </div>
              <div class="modal-container" :class="{ active: searchModal.toggle && isCurrentCameraBodyCam }">
                <span>
                    The service is currently not available for Body-Worn Cameras. If interested, we will email you as soon as it becomes available.
                </span>
                <div class="modal-btn-container">
                    <button @click="searchModal.toggle = false; roiToggle = false">I would like to try</button>
                    <button @click="searchModal.toggle = false; roiToggle = false">Not interested</button>
                </div>
              </div>
            <div style="position: relative;">
                <img class="watermark" v-if="waterMarkImage" :src="waterMarkImage" alt="">
            </div>
            <CameraViewNew
                :token="$store.state.account.token"
                @back="$router.push('/matrix')"
                v-if="$store.getters.cameras.length && streamInfo"
                ref="cv"
                :key="cvKey"
                :hideUI="hidePlayerUI"
                :timeOffset="$store.state.vvsTimeOffset"
                :getImage="getImage"
                :getImageForLiveBox="getImageForLiveBox"
                :roiEnabled="hiddenFeatureEnabled"
                :timeline="BodyCamTimeline ? BodyCamTimeline : timeline"
                :startDate="startDate / 1000"

                :gopWsUrl="activeCamera.connections.gopWsUrl"
                :metadataWsUrl="activeCamera.connections.metadataWsUrl"
                :metadataClassesUrl="activeCamera.connections.metadataClassesUrl"
                :metadataEnabled="this.boundingBoxesEnabled && (!!$route?.query?.debug || this.hiddenFeatureEnabled)"

                :streamId="activeCamera.id"
                :cameraId="activeCamera.deviceId"

                :showFps="!!$route?.query?.debug"
                :forceWasm="!!$route?.query?.forceWasm"
                :showDebugInfo="!!$route?.query?.debug"

                :getArchivePlaylist="getArchivePlaylist"
                :getLivePlaylist="getLivePlaylist"
                :getEventDetails="getEventDetails"
                @saveExport="saveExport"
                :events="cameraEvents"
                @selectEventType="selectEventType"
                :videoDimension="videoDimension"
                :eventTypes="cameraTypes"
                :initialTime="bodyCameraInitialStartTime ? bodyCameraInitialStartTime : (matrixTimeline ? matrixTimeline : currentTime)"
                @updateCrop="updateCrop"
                @updateTimeline="setMatrixTimeline"

                :newLiveButtonFlag="!onlyLive"
                :disableLiveButtonForBodyCameras="isCurrentCameraBodyCam ? true : false"
                :clearArea="$store.getters['camera/getClearArea']"
                :isAdmin="$route?.meta.isAdmin"
                :isEventQueryMode="eventList?.length>0"
                :eventList="eventList"
            />
        </div>
        <!-- <CameraGridBlob v-if="true && $store.getters.cameras.length" /> -->
    </div>

</template>

<script>
import Logger from "@/utils/logger.js"
// http://80.77.123.8:7999/api/playback/GERGO/GERGO_CAM_1/1624460822973/playlist.m3u8
// http://80.77.123.8:7999/api/playback/MOLA/MOLA_CAM_1/1634894615000/playlist.m3u8
import {Button, Search} from "@vidarex-vsaas/client-component-library";
// import Input from "@/components/Input.vue";
import Header from "@/components/Header.vue";
// import CameraGridBlob from "@/components/CameraGridBlob.vue";
// import videojs from "video.js";
import NotificationPanel from "../components/NotificationPanel.vue";
// import { mapState } from 'vuex'
import { ref } from "vue";
import { getQueries } from "../services/metaData"
import { useI18n } from "vue-i18n";
import jsonData from './jsonData.json'


const flatEvents = events => Object.values(events).flatMap(e => e)

const processEvents = (events, pickStart, pickEnd, setEnd) => {
    const lastEl = (arr) => arr[arr.length-1]
    return events.reduce((res, event) => {
        if (lastEl(res) === undefined) {
            res.push(event)
            return res
        }
        if (pickStart(event) <= pickEnd(lastEl(res)) && pickEnd(event) > pickEnd(lastEl(res)))
            setEnd(res[res.length-1], pickEnd(event))
        if (pickStart(event) > pickEnd(lastEl(res)))
            res.push(event)
        return res
    }, []);
}

const events2Timeline = (events) => {
    let pointer = 0
    return events.reduce((res, event) => {
        pointer //?
        event.fromPartialMs //?
        ;(event.fromPartialMs - pointer) //?
        res.push({length: 0, duration: (event.fromPartialMs - pointer) / 1000})
        pointer = event.fromPartialMs
        // pointer = event.toPartialMs - pointer
        res.push({length: (event.toPartialMs - pointer) / 1000, duration: (event.toPartialMs - pointer) / 1000})
        pointer = event.toPartialMs
        return res
    }, [])
}

export default {
    name: "VCamera",
    setup(){
      const notificationToggle = ref(false)
      const { t } = useI18n();
      return {
        notificationToggle,
        t
      }
    },
    data() {
        // const active = this.$store.getters.cameras.find((c) => c.id === this.id);

        return {
            removeAge: false,
            fromSliderValue: 18,
            toSliderValue: 60,
            analyticClipTags: [],
            matrixTimeline: 0,
            BodyCamTimeline: 0,
            bodyCameraInitialStartTime: 0,
            isCurrentCameraBodyCam: false,
            // playlistWS: active.connections.gopWsUrl,
            // playlistMDWS: active.connections.metadataWsUrl,
            currentTime: !isNaN(this.timestamp)?parseInt(this.timestamp):null,
            roi:{
                left: 0,
                top: 0,
                width: 1,
                height: 1
            },
            timeline: undefined,
            selectedEvent: "Multi Object",
            streamInfo: null,
            eventMap: {
                "Multi Object": "ultinous/ualarm/multiObjectDetection",
                Loitering: "ultinous/ualarm/loiteringDetection",
                Crowd: "ultinous/ualarm/crowdDetection",
                Intrusion: "ultinous/ualarm/intrusionDetection",
                "Zone Crossing": "ultinous/ualarm/zoneCrossingDetection",
            },
            cvKey: 1,
            filteredObjects : [],
            searchableObjects: [
            {text: "person"},
            {text: "bicycle"},
            {text: "vehicle"},
            {text: "animal"},
            ],
            selectedSearch : [],
            START_TIME: 1696348800000,
            analyticsTimeline: 0,
            jsonName: jsonData,
            currentObject: 0,
            theLastObject: 0,
            roiToggle: false,
            searchModal: {
                toggle: false,
                analyticsEnabledByUser: false
            },
            alertModal: {
                toggle: false,
            },
            ageModal: {
                toggle: false,
            },
            noResults: false,
            clippedTimeline: 0,
            eventList: [],
            boundingBoxesEnabled: true,
            firstCurrentTimeJump: true,
            firstCurrentTimeJumpTS: 0,
        };
    },
    created() {},
    props: ["id", "timestamp"],
    computed: {
            hiddenFeatureEnabled(){
                return this.$store.state.account.hiddenFeatureEnabled
            },
            waterMarkImage(){
                return sessionStorage.getItem('wm')
            },
            hideMenuUI(){
                return this.$route?.meta.hideUI
            },
            hidePlayerUI(){
                return this.$route?.meta.hideUI || this.$route?.meta.onlyLive
            },
            onlyLive(){
                return this.$route?.meta.onlyLive
            },
          suggestions (){
            /* let allObjectContainer = []
            let matchingObjects = []
            for(const key of Object.values(this.jsonName)) {
                allObjectContainer.push(key)
                for(const tag of this.analyticClipTags) {
                    if (tag.text == key.text) {
                        matchingObjects.push(key)
                        this.testFunc(key)
                    }
                }
            }
            const filteredObjects = allObjectContainer.filter(item => !matchingObjects.includes(item))
            // THIS IS FILTERING OUT EVERYTHING THAT IS NOT AN OBJECT IMPORTANT
            const asd = filteredObjects.filter(item => item instanceof Object) */
            const asd = Object.values(this.jsonName).filter(item => item instanceof Object)
            
            return asd
        },
        isReady() {
            return true
            // return this.$store.state.websocket.readyState === WebSocket.OPEN
        },
        startDate(){
            return this.streamInfo?.duration?.fromStamp
        },
        videoDimension(){
            return {
                width: this.streamInfo?.width,
                height: this.streamInfo?.height
            }
        },
        activeCamera(){
            return this.$store.getters["camera/getCurrentCamera"]
        },
        cameraTypes() {
            return Object.keys(this.eventMap);
        },
        cameraEvents() {
            return this.$store.state.events
                .filter(
                    (e, i) =>
                        (e.inferName === undefined ||
                            e.inferName === this.eventMap[this.selectedEvent]) &&
                        e.probability > 0.5 &&
                        (this.$store.state.events[i + 1] === undefined ||
                            e.begin != this.$store.state.events[i + 1].begin)
                )
                .map((e) => ({
                    ts: e.begin,
                    start: e.begin + this.$store.state.eventsTimeOffset,
                    end: e.end + +this.$store.state.eventsTimeOffset + 1000,
                    color: "#00E3D6",
                }));
        },
    },
    async mounted() {
        await this.loadMatrixCams()
        this.loadBodyCams() 
        this.checkIfCurrentCameraBodyCam()
        window.addEventListener("keypress", this.openHiddenControlRoom)

        this.$store.dispatch("getEvents", { camId: this.id, timestamp: Date.now() });
        if (!this.$store.state.camera.cameras.length) {
            // const scopes = await this.$store.dispatch("account/getScopes");
            const cameras = await this.$store.dispatch("camera/loadByScope", this.$store.state.account.token);
            cameras
        }
        
        if (!this.activeCamera) return this.$router.push('/matrix')
        this.roiToggle = this.activeCamera.analysisEnabled
        if (this.roiToggle)
            this.searchModal.analyticsEnabledByUser = true

        if (this.activeCamera.durations){
          let prevTime = 0

          this.timeline = this.activeCamera.durations.map(x => ({fromStamp: x.fromStamp / 1000, toStamp: x.toStamp / 1000})).reduce((acc, val)=>{
            if (val.fromStamp - prevTime != 0)
              acc.push({
                length: 0,
                duration: val.fromStamp - prevTime,
              })
            const d = val.toStamp - val.fromStamp
            acc.push({
              length: d,
              duration: d,
            })
            prevTime = val.toStamp
            return acc
          }, [])
        }
        this.streamInfo = this.activeCamera.streamInfo
        if (this.$route.query.message == "AnalyticClipsPage") {
            this.analyticClipTags = await this.$store.getters["camera/getAnalyticClipTags"]
            const length = this.analyticClipTags.length - 1
            this.jsonName = this.analyticClipTags[length]
            console.log("analyticClipTags: ",this.analyticClipTags)
            const timeline = await this.$store.getters["camera/getClipTimeline"]
            this.updateTimeline(timeline)
        }
        /* this.analyticClipTags = await this.$store.getters["getSelectedSearch"]
        console.log("SELECTED", this.analyticClipTags) */

    },
    methods: {
        async setMatrixTimeline(timelineFromCameraViewNew) {
            await this.$store.commit("camera/setMatrixTimeline", timelineFromCameraViewNew)
        },
        async routeToMatrix() {
            await this.$router.push('/matrix')
        },
        closeAnalyticAgeRange() {
            this.ageModal.toggle = false
            this.$refs.search.removeLastElementTriggeredByParent()
        },
        saveAnalyticAgeRange() {
            let ageTag = null
            if (this.analyticClipTags[this.analyticClipTags.length - 1].className == "age") {
                ageTag = this.analyticClipTags[this.analyticClipTags.length - 1]
            }
            if(!ageTag) return;
            ageTag.text = "Age"
            ageTag.minAge = this.fromSliderValue
            ageTag.maxAge = this.toSliderValue
            ageTag.text = `${ageTag.text}: ${ageTag.minAge} - ${ageTag.maxAge}`
            this.ageModal.toggle = false
        },
        controlAgeRangeInput(input) {
            if (input.target.id == "toSlider") {
                this.toSliderValue = Math.max(input.target.value, this.fromSliderValue)
            }
            if (input.target.id == "fromSlider") {
                this.fromSliderValue = Math.min(input.target.value, this.toSliderValue)
            }
        },
        async checkIfCurrentCameraBodyCam() {
            const booleanResponse = await this.$store.dispatch("camera/isCurrentCameraArchived")
            this.isCurrentCameraBodyCam = booleanResponse
        },
        async toAnalyticClipsPage() {
            if (this.analyticClipTags.length == 0) return
            await this.$store.commit("camera/setAllAnalyticClips", this.analyticsTimeline)
            this.$router.push('/analytic')
        },
        async selectMenu(event) {
            if (event.target.value === "set_notification") {
                this.toggleNotificationModal();
            }
            if (event.target.value === "notification_list") { 
                const {VUE_APP_ADMIN_ROOT} = process.env
                window.open(VUE_APP_ADMIN_ROOT, '_blank'); 
            }
            event.target.value = "";
        },
        async loadMatrixCams() {
            const currentMatrixTimeline = await this.$store.getters["camera/getCurrentMatrixTimeline"]
            this.matrixTimeline = currentMatrixTimeline
        },
        async loadBodyCams() {
            const bodycamera = await this.$store.getters["camera/getCurrentCamera"]
            if (bodycamera.initialStartingTime != 0) this.bodyCameraInitialStartTime = bodycamera.initialStartingTime
            this.BodyCamTimeline = bodycamera?.archivedTimestamp
            /* if (this.BodyCamTimeline !== undefined) {
                this.isCurrentCameraBodyCam = true
            } */
        },
        enableAccessVideoAnalytics(){
            this.searchModal.toggle = false
            this.searchModal.analyticsEnabledByUser = true
            this.roiToggle = true
            /* 
            Implement backend magic here
            */
        },
        denyAccessVideoAnalytics(){
            this.searchModal.toggle = false
            this.searchModal.analyticsEnabledByUser = false
            this.roiToggle = false
            /* 
            Implement backend magic here
            */
        },
        roiButtonAction(){
            if (this.roiToggle) {
                this.alertModal.toggle = true
                /* this.roiToggle = false
                this.searchModal.toggle = false */
            } else if (!this.roiToggle) {
                if (this.searchModal.analyticsEnabledByUser)
                    this.roiToggle = true
                if (!this.searchModal.analyticsEnabledByUser) {
                    this.searchModal.toggle = true
                }
            }
        },
        videoAnalyticsAvailabilityCheck(){
            if (!this.searchModal.analyticsEnabledByUser) {
                this.searchModal.toggle = true
                return true
            }
        },
        updateCrop(e){
            this.roi = e
            this.$store.commit("SET_CURRENT_CAMERA_ROI",e)
            console.log("roi",this.roi)
            this.queryCall()
        },

        async queryCall(){
            // This stops the query call if the searched object is not supported yet
            if (this.selectedSearch[0] == 100) return;
            this.$refs.cv.setZoom(0)
            this.currentTime = this.$refs.cv.time
            let counter = 0
            let results = []

            do {
                try {
                    if (this.analyticClipTags.length < 1) {
                        this.timeline = undefined
                        this.eventList = []
                        // this.cvKey = this.cvKey += 1
                        /* console.log(this.timeline) */
                    } else {
                        console.log('querycall')
                        let start,end
                        if (counter%2===0){
                            // odd
                            start =  this.currentTime - (60_000*60) * ((~~(counter/2)) + 1)
                            end = this.currentTime - (60_000*60) * (~~(counter/2))
                        } else {
                            // even
                            start =  this.currentTime + (60_000*60) * ((~~(counter/2)))
                            end = this.currentTime + (60_000*60) * (~~(counter/2)+ 1)
                        }

                        const active = this.$store.getters["camera/getCurrentCamera"]
                        const response = await getQueries(active.deviceId, start, end, this.selectedSearch, this.roi, this.$store.state.account.token)
                        if (!Object.keys(response.data).length) throw Error("empty")
                        let events = flatEvents(response.data)
                        results = [...events, ...results]
                        this.clippedTimeline = results
                        this.eventList = processEvents(results, e => e.fromPartialMs, e => e.toPartialMs, (e,n) => e.toPartialMs = n)
                        if (this.firstCurrentTimeJump) {
                            this.firstCurrentTimeJump = false
                        }
                        this.updateTimeline(results)
                    }
                } catch(e) {
                    Logger.log(e)
                }
            } while(counter++ < 24*15 && results.length < 200)
            if (!this.firstCurrentTimeJump) {
                this.firstCurrentTimeJump = true
                this.firstCurrentTimeJumpTS = (results[results.length - 1].fromPartialMs + results[results.length - 1].toPartialMs) / 2
                this.$refs.cv.stepEvent(Math.floor(this.firstCurrentTimeJumpTS))
            }
            await this.$store.commit("camera/setClipTimeline", this.clippedTimeline)
            if (results.length == 0) {
                this.noResults = true
                setTimeout(() => {
                    this.noResults = false
                }, 5000);
            }
            
        },
        searchBarValueUpdate(e){
            const proceed = this.videoAnalyticsAvailabilityCheck()
            if (proceed) return;
            this.updateTags(e)
            this.queryCall()
        },
        updateTags (e) {
            // this.$store.commit('camera/setTags', e)
            if (this.analyticClipTags.length > e.length) {
                this.jsonName = e
            }
            this.analyticClipTags = e
            let container = []
            let formatingContainer = []
            Object.values(e).map(element => formatingContainer.push(element.text))
            /* Object.entries(this.demoSearch).map(elem => {
                if (formatingContainer.includes(elem[0])){
                    elem[1].map(s => container.push(s.class))
                }
            }) */
            for (const key of Object.values(this.jsonName)) {
                 if (formatingContainer.includes(key.text)) {
                    /* if (key.text == "Vehicle") {
                        container.push([1,2,3,7])
                        this.jsonName = key */
                    
                    if (key.class instanceof Object) {
                        Object.values(key.class).forEach(e=>container.push(e))
                        const newKey = {...key}
                        delete newKey["class"]
                        this.jsonName = newKey
                    } else {
                        container.push(key.class)
                        this.jsonName = key 
                    }
                 }
            }
            this.selectedSearch = []
            this.selectedSearch = container
            this.$store.commit("SET_CLASSES", this.selectedSearch)
            this.$store.commit("camera/setAnalyticClipTags", this.analyticClipTags)
            if (this.analyticClipTags.length !== 0) {
                if (this.analyticClipTags[this.analyticClipTags.length - 1].className == "attributes") {
                    this.jsonName.age.text = "Age"
                }
                if (this.analyticClipTags[this.analyticClipTags.length - 1].className == "age") {
                    this.ageModal.toggle = true
                } else {
                    this.ageModal.toggle = false
                }
            }
            if (this.analyticClipTags.length == 0) {
                this.jsonName = jsonData
            }
        },
        async updateTimeline(input){
            /* console.log("updateTimeline", input) */
            let events = input
            //FIXME: WORKAROUND
            if (events.length === 0)
                events = [{
                    "trackId": "0000018a-f57a-37ae-0000-000000001da2",
                    "fromPartialMs": Date.now() - 60_000,
                    "fromFullMs": Date.now() - 60_000,
                    "toFullMs": Date.now() - 59_000,
                    "toPartialMs": Date.now() - 59_000
                }]
            this.timeline = this.calculateTimeLine(events)
        //   this.cvKey = this.cvKey += 1
        },
        calculateTimeLine(input){
            this.analyticsTimeline = input
            console.log("analyticsTimeline", this.analyticsTimeline)
            console.log("calculateTimeLine result",events2Timeline(processEvents(input, e => e.fromPartialMs, e => e.toPartialMs, (e,n) => e.toPartialMs = n)))
            return events2Timeline(processEvents(input, e => e.fromPartialMs, e => e.toPartialMs, (e,n) => e.toPartialMs = n))
        },
        toggleNotificationModal(){
          this.notificationToggle = !this.notificationToggle
        },
        selectEventType(event) {
            this.selectedEvent = event.target.value;
        },
        async getEventDetails(ts) {
            const details = await this.$store.dispatch("getEventDetails", {
                streamID: this.$store.state.streamID,
                ts,
            });
            return details;
        },
        async getImage(data) {
            Logger.log("getimageStart");
            try {
                if (this.$route?.meta.onlyLive) {
                    //TODO: hacky workaround for blur, no blur for transcoded image, car sevice pilot
                    throw "Loading";
                }

                const { image } = await this.$store.dispatch("camera/getCameraImage", {
                    index: this.$store.state.camera.cameras.findIndex(c => c.id === this.id),
                    time: data.timestamp,
                    options: {
                        timelineEnd: 5,
                        quality: 60,
                    }
                });

                Logger.log("getimage", image);
                return image;
            } catch(error){
                throw new Error(error)
            }
        },
        async getImageForLiveBox(data) {
            Logger.log("getimageStart");
            try {
                const { image } = await this.$store.dispatch("camera/getCameraImage", {
                    index: this.$store.state.camera.cameras.findIndex(c => c.id === this.id),
                    time: data.timestamp,
                    options: {
                        timelineEnd: 5,
                        quality: 60,
                        targetWidth: 400
                    }
                });

                Logger.log("getimage", image);
                return image;
            } catch(error){
                throw new Error(error)
            }
        },
        saveExport(start, end) {
            const active = this.$store.getters["camera/getCurrentCamera"]
            if (end < start)
                end = start
            window.open(
                `${active.connections.http}api/cameras/${
                    this.id
                }/export/mp4?begin=${Math.round(start)}&end=${Math.round(end)}&token=` + this.$store.state.account.token
            );
        },
        getArchivePlaylist(ts) {
          console.log('ARCHIVED PLAYLIST')
            const active = this.$store.getters["camera/getCurrentCamera"]
            return Promise.resolve(
                `${active.connections.http}api/playback/${active.id}/${ts}/playlist.m3u8?token=` + this.$store.state.account.token
                );
        },
        getLivePlaylist() {
          console.log('ACTIVE PLAYLIST')
            const active = this.$store.getters["camera/getCurrentCamera"]
            return Promise.resolve(
                `${active.connections.http}api/playlist/${active.id}/playlist.m3u8?token=` + this.$store.state.account.token
            );
        },
        toggleBoundingBoxes(){
            this.boundingBoxesEnabled = !this.boundingBoxesEnabled
            this.cvKey++
        }
    },
    components: {
    Button,
    Header,
    NotificationPanel,
    Search
},
    watch: {
        async id() {
            //TODO: fix this
            window.scrollTo(0, 0);
            this.$store.dispatch("setLoading", true);
            this.$refs.cv.playlist = null;
            this.$refs.cv.preview = undefined;
            this.$refs.cv.isStarted = false;
            await this.$refs.cv.updatePreview();
            // alert();
            // setTimeout(() => {
            this.$refs.cv.seekingEnd();
            this.$store.dispatch("setLoading", false);
            // }, 500);
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.analytic-btn {
    background-color: #222;
}

.analytic-btn.active {
    filter: brightness(4);
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.roi-checkbox.checked + .slider {
    background-color: #2196F3;
}

.roi-checkbox:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.roi-checkbox.checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.modal-container {
    background-color: white;
    color: black;
    position: absolute;
    top: 10%;
    left: 30%;
    z-index: 101;
    width: 400px;
    padding: 25px;
    border-radius: 10px;
    display: none;
    flex-direction: column;
    align-items: center;
}

.age-container {
    background-color: white;
    color: black;
    position: absolute;
    top: 10%;
    left: 30%;
    z-index: 101;
    display: none;
    width: 300px;
    padding: 25px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
}

.age-container.active {
    display: flex;
    flex-direction: column;
}

.modal-container.active {
    display: flex;
    flex-direction: column;
}

.modal-container-model {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
}

.modal-container-model.select {
    width: 100%
}

.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #88888866;
}

.modal-btn-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.modal-btn-container button {
    background-color: #44bacc;
    border: 0px;
    border-radius: 10px;
    padding: 5px 10px;
}

.switch:hover + .roi-tooltip,
.roi-tooltip.active {
    visibility: visible;
}

.roi-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    gap: 10px;
}

.roi-tooltip {
    visibility: hidden;
    background-color: #929698;
    color: #333333;
    padding: 7px;
    border-radius: 10px;
    opacity: .9;
}

.roi-btn {
    background-color: #929698;
    color: #333333;
    border: 0px;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;
}

.roi-btn.active {
    background-color: #44bacc;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

.no-results {
    position: absolute;
    left: 50%;
    background-color: grey;
    padding: 10px;
    border-radius: 10px;
    opacity: .9;
}

.bounding-box {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}

.watermark {
    position: absolute;
    left: 0;
    top: 0;
    margin: 20px;
    pointer-events: none;
    z-index: 10;
    max-width: 200px;
    max-height: 200px;
}
.notification-button {
  display: flex;
  align-items: center;
}

.notification-content {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 300,
  'GRAD' 0,
  'opsz' 24
}
.header-toolbar {
    margin-left: 10px;
    min-width: 5rem;
}

.notification-modal {
  display: flex;
  justify-content: center;
}

.search-box {
    width: 100%;
    max-width: 600px;
    margin: auto
}


.page {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #000000;
    color: #ffffff;
    padding-top: $header-size;
    overflow: hidden;
    &::after {
        height: 1px;
        margin-bottom: -1px;
        content: "";
        display: block;
    }
}

.menu-top {
    font-size: 14px;
    font-weight: 700;
    margin-left: auto;
}

.container {
    max-width: $container-width;
    margin: auto;
}

.video-screen {
    width: 100%;
    height: 60vh;
    position: absolute;
    overflow: hidden;
    z-index: 0;
}

.controls {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    margin: auto auto 0;
}

.controls-group {
    display: flex;
    align-items: center;
    &:first-child,
    &:last-child {
        flex: 1;
    }
    &:last-child {
        justify-content: end;
    }
}

.sign-out {
    color: #00a0b6;
    font-weight: 900;
    margin-left: 10px;
}

.section-player {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    height: calc(100vh - 0px);
    padding: 0 40px;
}

.video-player {
    width: 100%;
    height: calc(100vh - 40px);
    height: calc(100vh - 0px);
    margin-top: $header-size * -1;
    padding-top: $header-size;
    box-sizing: border-box;
}

.icon {
    width: 24px;
    height: 24px;
}
#my-video {
    width: 100%;
    height: 100%;
}
.section-matrix {
    background-color: #222222;
    padding: 40px;
}
.hidden-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.add {
    background-color: #222;
}

.add--active {
    filter: brightness(4);
}

.range-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .age-range-container {
    display: flex;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .sliders-control {
    position: relative;
    min-height: 20px;
    margin-top: 10px;
  }
  
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }
  
  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;  
  }
  
  input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
  }
  
  input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
    -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }
  
  input[type="number"] {
    color: #8a8383;
    width: 50px;
    height: 30px;
    font-size: 20px;
    border: none;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {  
     opacity: 1;
  }
  
  input[type="range"] {
    -webkit-appearance: none; 
    appearance: none;
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: #C6C6C6;
    pointer-events: none;
  }
  
  #fromSlider {
    height: 0;
    z-index: 1;
  }

</style>

<template>
  <router-view />
  <LoadingOverlay />
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
export default {
  created(){
    this.$store.dispatch("setLoading", true);
  },
  mounted(){
    window.addEventListener("keypress", this.openHiddenControlRoom)
    this.$store.dispatch("setLoading", false);
  },
  unmounted(){
    window.removeEventListener("keypress", this.openHiddenControlRoom)
  },
  components: {
    LoadingOverlay
  },
  methods: {
    openHiddenControlRoom($event){
        if ($event.shiftKey && $event.code === 'KeyC'){
            this.$store.commit("account/hiddenFeatureEnable")
        }
    },
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
</style>

<template>
  <div class="page">
    <div class="stock">
      <img class="logo" src="@/assets/logo/main.svg" alt="Vidarex" />
    </div>
    <div class="login-box">
      <Form
        @submit="login"
        ref="form"
        novalidate="novalidate"
        v-slot="{ errors }"
        autocomplete="off"
      >
        <h1 class="header">{{ $t("login.signIn") }}</h1>
        <h2 class="subhead">{{ $t("register.form.enterDetails") }}</h2>
        <template v-if="isUseCode">
          <Input
            v-model="code"
            :label="$t('login.code')"
            :info="error"
            :infoType="error ? 'error' : ''"
            @update:modelValue="error = ''"
          />
        </template>
        <template v-else>
          <Field name="email" v-slot="{ handleChange, value, errorMessage }">
            <Input
              :modelValue="value"
              @update:modelValue="handleChange"
              :label="$t('register.form.emailAddress')"
              :info="errorMessage"
              :infoType="errorMessage && 'error'"
            />
          </Field>
          <Field name="password" v-slot="{ handleChange, value, errorMessage }">
            <Input
              type="password"
              :modelValue="value"
              @update:modelValue="handleChange"
              :label="$t('register.form.password')"
              :info="errorMessage"
              :infoType="errorMessage && 'error'"
            />
          </Field>
          <a
            class="forgot_link"
            @click.prevent="$router.push({ name: 'ResetPassword' })"
          >
            {{ $t("login.forgottenPassword") }}
          </a>
        </template>
        <Field name="rememberMe" v-slot="{ handleChange, value, errorMessage }">
          <Checkbox
            :modelValue="value"
            @update:modelValue="handleChange"
            :info="errorMessage"
            :infoType="errorMessage && 'error'"
          >
            {{ $t("login.rememberMe") }}
          </Checkbox>
        </Field>
        <div>
          <Info type="success-block" v-if="verified">
            {{ $t("activation.successfulEmail") }}
            {{ $t("activation.nowLogin") }}
          </Info>
          <Info type="success-block" v-if="pwChanged">
            {{ $t("changePassword.successfulPwChange") }}
          </Info>
          <Info type="error-block" v-else-if="verificationError">
            {{ $t("activation.unsuccessfulEmail") }} ({{ verificationError }})
          </Info>
          <Info type="error">
            {{ errors.other }}
          </Info>

          <Button :text="$t('login.signInBtn')" type="submit" />
        </div>
      </Form>
      <p>
        {{ $t("login.dontHaveAnAcc") }}
        <a @click="$router.push('registration')">{{ $t("login.register") }}</a>
        <!-- or use
                <a v-if="!isUseCode" @click="isUseCode=true">Code</a>
                <a v-else @click="isUseCode=false">Email and password</a> -->
      </p>
      <hr class="separator" />
      <h1>{{ $t("login.try") }}</h1>
      <p>{{ $t("login.tryText") }}</p>
      <Button
        theme="secondary"
        :text="$t('login.startDemo')"
        @click="loginDemo()"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import Info from "@/components/Info.vue";
import Input from "@/components/Input.vue";
import Checkbox from "@/components/Checkbox.vue";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import{ ROUTE_NAMES } from '@/router/routes'

export default {
  name: "VLogin",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  data() {
    return {
      isUseCode: false,
      code:
        JSON.parse(this.$store.state.storage.getItem("account"))?.email ?? "", // FIXME
      error: "",
      email: "",
      password: "",
      verified: this.$route.query.success !== undefined,
      pwChanged: this.$route.query.pwChangeSuccess !== undefined,
      verificationError: this.$route.query.verificationError,
    };
  },
  mounted() {},
  methods: {
    async loginDemo() {
      return this.login({
        email: process.env.VUE_APP_DEMO_ACCOUNT_EMAIL,
        password: process.env.VUE_APP_DEMO_ACCOUNT_PASSWORD,
      });
    },
    async login(data) {
      this.$store.dispatch("setStorage", data.rememberMe?localStorage:sessionStorage);
      this.$store.dispatch("setLoading", true);
      const response = await this.$store
        .dispatch("account/login", {
          ...data
        })
        .then(async () => {
          this.$store.dispatch("camera/reset");
          this.$router.push({name: ROUTE_NAMES.HOME});
        })
        .catch((error) => {
          this.$store.dispatch("setLoading", false);
          if (error.response.status === 500)
            if (error.response.data?.extra?.inactive)
              this.$refs.form.setErrors({
                other: this.t("login.err.confirmEmail"),
              });
            else
              this.$refs.form.setErrors({
                other: this.t("login.err.wrongEmailOrPw"),
              });
        })
        .finally(() => {
          // this.$store.dispatch("setLoading", false);
        });
      this.$store.state.storage.setItem("Login/rememberMe", !!data.rememberMe);
      response;
    },
  },
  components: {
    Button,
    Input,
    Checkbox,
    Field,
    Form,
    Info,
  },
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.logo {
  position: fixed;
  margin: 50px;
  display: none;
  @include for-tablet-up {
    display: block;
  }
}

.separator {
  height: 1px;
  border: 0;
  background: #1f211c;
  opacity: 0.1;
  margin: 40px 0;
}

.confirmationDone {
  background-color: green;
  text-align: center;
  color: white;
  padding: 10px;
}

.page {
  display: flex;
  height: 100%;
  background-color: white;
}

.stock {
  background-image: url("../assets/images/promos/login_bg_2.jpg");
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  @include for-tablet-up {
    width: 4 * $col;
  }
}
.login-box {
  width: 10 * $col;
  @include for-tablet-up {
    width: 6 * $col;
  }
  @include for-desktop-up {
    width: 4 * $col;
  }
  height: 80%;
  max-width: 500px;
  margin: auto;
}

.header {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.subheader {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.forgot_link {
  display: block;
  text-align: right;
  float: right;
  position: relative;
  z-index: 2;
}
</style>

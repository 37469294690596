<template>
    <div class="header">
        <img class="logo" src="@/assets/logo/small.svg" alt="Vidarex" />
      <slot />
      <div class="menu-top">
        <div class="menu-icons">
          <a :href="directToAdmin" target="_blank">
            <img class="gear" src="@/assets/icons/player/gear.svg"  alt="Admin" />
          </a>
          <a class="sign-out" href="#" @click="logout"> {{ $t("demo.signOut") }}</a>
        </div>
      </div>
    </div>
</template>

<script>
// import {Button} from "@vidarex-vsaas/client-component-library";
export default {
    name: "VHeader",
    components: {
    // Button,
},
    data(){
        return {
            tags: []
        }
    },
    methods: {
        logout(){
            document.documentElement.scrollTo(0,0); // FIXME: only works well if you log out from matrix page
            // this.$router.push('/');
            // this.$store.dispatch('closeWebsocket');
            this.$store.dispatch('logout');
        }
    },
    computed: {
      directToAdmin() {
       const {VUE_APP_ADMIN_ROOT} = process.env;
       return `${VUE_APP_ADMIN_ROOT}auth/login`;
      }
    }
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.header {
    position: fixed;
    background-color: #000000;
    height: $header-size;
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 100;
}

.menu-top {
    font-size: 14px;
    font-weight: 700;
    margin-left: auto;
    flex-grow: 0;
}

.logo {
    height: 16px;
}
.sign-out {
    color: white;
}

.menu-icons {
  display: flex;
  align-items: center;
}

.gear {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}
</style>

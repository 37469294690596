<template>
    <div class="overlay" v-if="$store.state.isLoading">
        <Loading class="icon" />
    </div>
</template>

<style scoped>
.overlay {
    animation: fadein 0.4s;
    display: flex;
    backdrop-filter: blur(2px);
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
}
.icon {
    width: 60px;
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>

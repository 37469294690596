<template>
    <label class="label" :class="infoType" :for="$uuid">{{ label }}</label>
    <div class="input__container">
        <input
            class="input"
            :class="infoType"
            :disabled="disabled"
            :placeholder="placeholder"
            :id="$uuid"
            :type="visibleType"
            :name="name"
            :autocomplete="autocomplete"
            :value="modelValue"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <div v-if="type==='password'" class="input__icon material-symbols-outlined" @click="togglePasswordVisibility">
            {{
                passwordVisibility ?
                "visibility" :
                "visibility_off"
            }}
        </div>
    </div>
    <span class="info" :class="infoType">{{ info }}</span>
</template>

<script>
export default {
    name: "BasicInput",
    data() {
        return {
            passwordVisibility: false
        }
    },
    props: {
        label: String,
        name: String,
        modelValue: String,
        info: String,
        autocomplete: String,
        placeholder: String,
        infoType: {
            default: "info",
            type: String,
        },
        type: {
            default: "text",
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        visibleType(){
            if (this.type === 'password' && this.passwordVisibility)
                return 'text'
            return this.type
        }
    },
    methods: {
        togglePasswordVisibility(){
            this.passwordVisibility = !this.passwordVisibility
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin: 15px 0;
}
.input {
    display: block;
    width: 100%;
    background: #f4f4f4;
    border: 1px solid #c1cbcc;
    box-sizing: border-box;
    border-radius: 6px;
    min-height: 60px;
    padding: 19px 20px;
    line-height: 19px;
    font-size: 16px;
    margin: 20px 0;
}
.info.error {
    display: block;
    color: #ff6565;
    margin-bottom: 20px;
}
.input.error {
    background-color: #ff6565;
    border-color: #ff6565;
    color: white;
    margin-bottom: 4px;
}
.label.error {
    color: #ff6565;
}

.input__container {
    position: relative;
}
.input__icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 19px 20px;
    cursor: pointer;
    font-smooth: always;
}
::-ms-reveal {
    display: none;
}
.input__icon::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

</style>

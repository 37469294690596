<template>
  <div class="overlay" :class="this.toggle ? 'show' : 'hidden'" @click="closeNewAlarm"></div>
  <div :class="this.toggle ? 'container' : 'hidden'">
    <div class="name-type-container">
      <input
        type="text"
        :placeholder="$t('notification.newAlarm')"
        class="custom-input"
        tabindex="1"
        v-model="name"
      />
      <p class="heading">{{$t('notification.type')}}</p>
      <form class="notification-type">
        <label class="type-container" for="sms"
          >{{$t('notification.sms')}}
          <input
            name="sms"
            value="sms"
            id="sms"
            type="checkbox"
            checked="checked"
            v-model="notificationTypes"
            tabindex="2"
          />
          <span class="checkmark"></span>
        </label>

        <label class="type-container" for="email"
          >{{$t('notification.email')}}
          <input
            name="email"
            value="email"
            id="email"
            type="checkbox"
            checked="checked"
            v-model="notificationTypes"
            tabindex="3"
          />
          <span class="checkmark"></span>
        </label>
      </form>
        <div class="hours">
            <label class="time-container" for="selectedStartHour"
          >{{$t('notification.from')}}</label>
            <input
              type="time"
              id="selectedStartHour"
              v-model="selectedStartHour"
              tabindex="12"
            />
            <label class="time-container" for="selectedEndHozr"
          >{{$t('notification.to')}}</label>
            <input
              type="time"
              id="selectedEndHour"
              v-model="selectedEndHour"
              tabindex="13"
            />
          </div>

    </div>
      <div class="visualizer">
        <div class="day-visualizers">
          <div
          @click="selectDay(day)"
            v-for="(day, index) in daysOfWeek.keys()"
            :key="index"
            class="day-visualizer"
          >
            <Button

              :class="
                this.selectedDays.includes(daysOfWeek.get(day))
                  ? 'day-btn'
                  : 'day-btn day-inactive'
              "
              :tabindex="index + 4"
              >{{ day }}</Button
            >
            <div
              :class="{
                notSelectedDay: !selectedDays.includes(daysOfWeek.get(day)),
                'time-range-visualizer': true,
              }"
            >
              <TimeRange
                :completeRange="1440"
                :selectedStartHour="this.selectedStartHour"
                :selectedEndHour="this.selectedEndHour"
              ></TimeRange>
            </div>
          </div>

        </div>
      </div>
      <p class="specify">
        {{$t('notification.specify')}}
      </p>
    <div class="button-container">
      <Button class="btn add" @click="saveNewAlarm" tabindex="14">
        {{$t('notification.create')}}
      </Button>
      <Button class="btn" @click="closeNewAlarm" tabindex="15"> {{$t('buttons.cancel')}} </Button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import TimeRange from "@/components/TimeRange.vue";
import {
  getCamerasCameraIdAlarms,
  postCamerasCameraIdAlarms,
} from "../services/apiv2";
import { Button } from "@vidarex-vsaas/client-component-library";
export default {
  name: "NotificationPanel",
  props: ["toggle", "streamId"],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  data() {
    const daysOfWeek = new Map([
      [this.t('notification.daysShort.mon'), "MONDAY"],
      [this.t("notification.daysShort.tue"), "TUESDAY"],
      [this.t("notification.daysShort.wed"), "WEDNESDAY"],
      [this.t("notification.daysShort.thu"), "THURSDAY"],
      [this.t("notification.daysShort.fri"), "FRIDAY"],
      [this.t("notification.daysShort.sat"), "SATURDAY"],
      [this.t("notification.daysShort.sun"), "SUNDAY"],
    ]);
    return {
      name: "",
      daysOfWeek,
      notificationTypes: [],
      selectedDays: [...daysOfWeek.values()],
      selectedStartHour: "00:00",
      selectedEndHour: "00:00",
    };
  },
  components: {
    Button,
    TimeRange,
  },
  computed: {
    getSelectedStartMinutes() {
      return (
        Number(this.selectedStartHour.split(":")[0]) * 60 +
        Number(this.selectedStartHour.split(":")[1])
      );
    },
    getSelectedEndMinutes() {
      return (
        Number(this.selectedEndHour.split(":")[0]) * 60 +
        Number(this.selectedEndHour.split(":")[1])
      );
    },
  },
  methods: {
    async load() {
      await getCamerasCameraIdAlarms({});
    },
    selectDay(shortDay) {
      const selectedDay = this.daysOfWeek.get(shortDay);
      if (this.selectedDays.includes(selectedDay)) {
        this.selectedDays = this.selectedDays.filter(
          (day) => day !== selectedDay
        );
      } else {
        this.selectedDays.push(selectedDay);
      }
    },
    isMinuteInSelectedRange(minute) {
      const startMinutes = this.getSelectedStartMinutes;
      const endMinutes = this.getSelectedEndMinutes;

      // Handle the case where the selected range spans midnight (e.g., 22:00 to 02:00)
      if (startMinutes > endMinutes) {
        return minute >= startMinutes || minute <= endMinutes;
      }

      return minute >= startMinutes && minute <= endMinutes;
    },
    closeNewAlarm() {
      (this.name = ""),
        (this.notificationTypes = []),
        (this.selectedDays = [...this.daysOfWeek.values()]),
        (this.selectedStartHour = "00:00"),
        (this.selectedEndHour = "00:00"),
        this.$emit("close");
    },
    async saveNewAlarm() {
      if (this.notificationTypes.length <= 0) {
        return alert(this.t('notification.error.type'));
      }
      if (this.name.length <= 0) {
        return alert(this.t('notification.error.name'));
      }
      try {
        const activeCamera = this.$store.getters.cameras.find(
          (c) => c.id === this.streamId
        );
        await postCamerasCameraIdAlarms(activeCamera.deviceId, {
          enabled: true,
          name: this.name,
          filter: {
            roi: this.$store.state.currentCameraRoi ? this.$store.state.currentCameraRoi : {
                left: 0,
                top: 0,
                width: 1,
                height: 1
            },
          },
          classes: this.$store.state.classes,
          minTrackDurationMs: 0,
          minLoiteringDurationMs: 0,
          cooldownMs: 300000,
          daysOfWeek: this.selectedDays,
          intervalFromMin: this.getSelectedStartMinutes,
          intervalToMin: this.getSelectedEndMinutes,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        this.closeNewAlarm()
      } catch (error) {
        alert("Hiba történt!");
        this.closeNewAlarm()
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.specify {
  font-weight: 600;
  text-align: center;
  color: #666;
  font-size: 14px;
  margin: 10px 10px 20px 10px;
}
.visualizer {
  gap: 5px;
  display: block;
  flex-wrap: wrap;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: #fff;
  padding: 40px 10px 0px 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.day-visualizers {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.day-visualizer {
  flex: 1;
  text-align: center;
  margin-top: -15px;
  width: 60px;
  cursor: pointer;
}
.time-range-visualizer {
  display: flex;
  width: 90px;
}

.notSelectedDay {
  display: none;
}

input[type="time"] {
  position: relative;
  padding: 8px;
  background-color: white;
  color: black;
  font-weight: 600;
  font-size: 12px;
  border-radius: 3px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: #88888866;
  z-index: 100;
  cursor: default
}

.overlay.show {
  display: block;
}
#sms {
  accent-color: #222;
}

#email {
  accent-color: #222;
}
.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  padding: 10px;
  /* max-width: 510px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  user-select: none; /* Standard syntax */
  cursor: default
}

.active {
  background-color: #222;
  font-size: 15px;
}

.inactive {
  background-color: grey;
  font-size: 15px;
}

.btn:hover {
  filter: brightness(115%);
}

.hidden {
  display: none;
}

.heading {
  font-size: 18px;
  font-weight: 600;
}

.notification-type {
  display: flex;
  gap: 10px;
  color: #fff;
}

.type-container {
  display: flex;
  color: #fff;
}

.selected-type {
  color: #fff;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px 15px 15px 15px;
}

.new-view-form .btn.add {
  margin: 12px 0;
}
.add {
  background-color: #222;
}

.day-inactive {
  color: grey;
}

.day-btn {
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}
.btn {
  font-weight: 500;
}

.type-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
}
.time-container {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
  user-select: none;
}

/* Hide the browser's default checkbox */
.type-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.type-container:hover input ~ .checkmark {
  filter: brightness(115%);
}

/* When the checkbox is checked, add a blue background */
.type-container input:checked ~ .checkmark {
  background-color: #222;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.type-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.type-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-input {
  font-size: 17px;
  outline: none;
  font-weight: bold;
  width: 30%;
  max-width: 100px;
  padding: 0.75rem;
  border: none;
  border-radius: 3px;
  transition: 0.1s;
  margin-right: 20px;
  min-width: 190px;
}

.custom-input:focus {
  font-weight: normal;
}

.name-type-container {
  padding: 10px 10px 0px 10px;
  margin: 15px 10px 0px 10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.hours {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-top: -5px;
}

.days {
  padding-right: 5px;
}

.hours {
  padding-left: 20px;
}
</style>

<template>
    <div class="page">
        <Header>
            <div class="header-toolbar">
                <MatrixLiveSwitcher :matrix="true" :bodycam="areArchivedCameras"/>
            </div>
            <div class="search-box">
                <Search :key="matrixSearch" ref="search" :modelValue="tags" @update:modelValue="updateTags"
                    :suggestions="suggestions" />
            </div>
        </Header>
        <CameraGridBlob v-if="$store.getters.cameras.length" :timestamp="timestamp" :isSeaking="isSeaking" :isLive="isLive"/>
        <div v-else style="padding: 100px 0; text-align:center; justify-content: center">
            <span>
                {{ $t("matrix.noCameras") }}
            </span>
        </div>
        <div class="timeline-container" v-if="timelineReady">
            <div :class="zoomClass" class="seeker-box" @click="clickFormatedTime">
                <h1 class="seeker-time" v-html="formatedTime"></h1>
                <button class="live-button" :class="{disabled: !isLive }" @click="goToLive">LIVE</button>
            </div>
            <div class="timeline-box">
                <Timeline v-model="timestamp" :sticky="false" :stack="stack" @move="updateTimestamp" @end="endTrigger" :zoomLevel="zoomIndex" @zoomLevelNearest="zoomLevelNearest"/>
            </div>
        </div>
    </div>
</template>

<script>
import Logger from "@/utils/logger.js"
import Header from "@/components/Header.vue";
import CameraGridBlob from "@/components/CameraGridBlob.vue";
import MatrixLiveSwitcher from "@/components/MatrixLiveSwitcher.vue";
import { Search } from "@vidarex-vsaas/client-component-library";
import { Timeline } from "@vidarex-vsaas/client-component-library";
import { mapState } from 'vuex'
import { ROUTE_NAMES } from '@/router/routes'

export default {
    name: "App",
    components: {
        Header,
        CameraGridBlob,
        Search,
        MatrixLiveSwitcher,
        Timeline,
    },
    data(){
        const startDate = (Date.now() - 1000*60*60*24*7) / 1000
        const endDate = Date.now()  / 1000
        return {
            stack: [
                { length: 0, duration: startDate },
                { length: endDate - startDate, duration: endDate - startDate },
                ],
            timestamp: Date.now(),
            intervalId: 0,
            startDate: (Date.now() - 1000*60*60*24*7) / 1000,
            timelineReady: false,
            headerToggle: [
                {
                    label: "Matrix",
                    value: "matrix"
                },
                {
                    label: "LMV",
                    value: "LMV"
                },
            ],
            isSeaking: false,
            zoomIndex: 3,
            isLive: true,
        }
    },
    async created() {
        // const scopes = await this.$store.dispatch("account/getScopes");
        // const camera = await this.$store.dispatch("camera/loadByScope", scopes[0]);
        Logger.log("created")
    },
    async mounted() {
        window.addEventListener("keypress", this.openHiddenControlRoom)
        await this.loadMatrixCams()
        this.startDateCalc()
        this.startTimeline()
        this.isLiveFunc()
        
        // Logger.log(await this.$store.dispatch("site/load"));
        // if (!this.$store.state.camera.cameras.length) {
        //     await this.$store.dispatch(
        //         "site/loadSitesByCode",
        //         this.$store.state.account.email
        //     );
        //     await this.$store.dispatch(
        //         "camera/loadBySites",
        //         this.$store.state.site.sites
        //     );
        // }
    },
    unmounted() {
        window.removeEventListener("keypress", this.openHiddenControlRoom)
        clearInterval(this.intervalId)
    },
    computed: {
        zoomClass() {
            return "zoom-" + this.zoomIndex;
        },
        formatedTime() {
        const t = new Date(this.timestamp);
        return `
                <span class="year">
                    ${this.dateTransformer(t.getFullYear())}.</span><span class="month">${this.dateTransformer(t.getMonth() + 1)}.</span><span class="day">${this.dateTransformer(t.getDate())}.</span>
                <span class="hour">
                    ${this.dateTransformer(t.getHours())}:</span><span class="minute">${this.dateTransformer(t.getMinutes())}:</span><span class="second">${this.dateTransformer(t.getSeconds())}</span>
                `;
        },
        ...mapState("camera", {
            tags: state => state.tags,
            areArchivedCameras: state => state.areArchivedCameras,
            cameras: state => state.cameras
        }),
        suggestions() {
            if (this.$store.getters.filteredCameras.length === 1)
                return []


            return this.$store.getters.filteredCameras
                .map(c => c.tags)
                .flat()
                .filter(tag => !this.tags.map(t => t.value.name).includes(tag.name))
                .sort((a, b) => {
                    if (b.type - a.type === 0) {
                        if (a.level === undefined || a.level - b.level === 0) {
                            return a?.name?.localeCompare(b?.name)
                        }
                        return a.level - b.level
                    }
                    return b.type - a.type
                })
                .map(tag => ({ text: tag.name, value: tag }))
                .reduce((newArr, value) => {
                    if (!newArr.map(a => a.text).includes(value.text))
                        newArr.push(value)
                    return newArr
                }, [])
                .filter(
                    (tag) => {
                        return !this.$store.getters.filteredCameras.every(cam => cam.tags.map(t => t.name).includes(tag.text))
                    }
                )
            // .sort((a,b) => {
            //     return a.order - b.order
            //     // return a.text.localeCompare(b.text)
            // })
            // [
            //     // ...this.$store.getters.filteredCameras.filter(c => Array.isArray(c.site)).map(c => c.site).flat().map(s => s.name.defaultText),
            //     // ...this.$store.getters.filteredCameras.map(c => c.name)
            // ]

        },
    },
    methods: {
        isLiveFunc() {
            if (this.timestamp + 5000 > Date.now()) {
                this.isLive = true
            } else {
                this.isLive = false
            }
        },
        async loadMatrixCams() {
            const currentMatrixTimeline = await this.$store.getters["camera/getCurrentMatrixTimeline"]
            if (!currentMatrixTimeline) return;
            this.timestamp = currentMatrixTimeline
        },
        dateTransformer(input) {
         if (String(input).length == 1) return "0" + input

         return input
        },
        goToLive() {
            this.updateTimestamp(Date.now())
            this.isLive = true
            setTimeout(() => {
                this.endTrigger()
            }, 100);
        },
        startDateCalc() {
            const allCameraDurations = this.cameras.map(e => {
              return e.streamInfo?.duration?.fromStamp
            }).filter(e => e !== undefined)
            if (allCameraDurations.length === 0 || allCameraDurations === undefined) {
                return;
            }
            let earliestStartDate = Math.min(...allCameraDurations) / 1000
            this.stack[0] = { length: 0, duration: earliestStartDate }
            this.startDate = earliestStartDate
            this.timelineReady = true
        },
        clickFormatedTime(event) {
            const map = {
                year: 3,
                month: 3,
                day: 3,
                hour: 2,
                minute: 1,
                second: 0,
            };
            if (map[event.target.className] !== undefined) {
                this.setZoom(map[event.target.className]);
            }
        },
        setZoom(value) {
            // workaround for flickering
            // I counter workarouned the workaround because it was not working around for me.
            /* const prev = this.zoomIndex; */
            this.zoomIndex = value;
            /* setTimeout(() => {
                this.zoomIndex = prev;
            }, 0); */
        },
        zoomLevelNearest(value) {
            this.zoomIndex = value;
        },
        endTrigger(){
           setTimeout(() => {
               this.isSeaking = false
            }, 1000);
            setTimeout(() => {
                this.isSeaking = true
                this.timestamp = this.timestamp + 10
            }, 800);
        },
        startTimeline(){
            this.intervalId =
            setInterval(()=> {
                const endDate = Date.now()  / 1000
                this.stack[this.stack.length-1] = {
                    length:endDate - this.startDate,
                    duration:endDate - this.startDate,
                }
                if (!this.isSeaking && this.isLive) this.timestamp = Date.now()
            }, 1000/60)
        },
        updateTimestamp(updateValue){
            if ( isNaN(updateValue) || updateValue === undefined || updateValue === Infinity ) return;
            this.isSeaking = true
            this.timestamp = updateValue
            return this.isLiveFunc()
        },
        updateTags (e) {
            this.$store.commit('camera/setTags', e)
        },
        openHiddenControlRoom($event) {
            if ($event.shiftKey && $event.code === 'KeyX')
                this.$router.push({ name: ROUTE_NAMES.CONTROL })
        }
    },
    watch: {
        /* timestamp() {
            if (this.timestamp + 5000 > Date.now()) {
                console.log(this.timestamp)
            }
        } */
    }
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.live-button {
    cursor: pointer;
    background: #ff6565;
    background: #00318d;
    padding: 4px 8px;
    color: white;
    border-radius: 4px;
    border: 0;
    font-size: 16px;
}

.live-button.disabled {
    background: #ffa80066;
    background: #ff6565;
    background: #00e3d6;
    color: black;
    opacity: 0.8;
}

.search-box {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.zoom-0 .second {
    color: white;
  }
  .zoom-1 .minute {
    color: white;
  }
  .zoom-2 .hour {
    color: white;
  }
  .zoom-3 .month,
  .zoom-3 .day {
    color: white;
  }
  .select {
    /* Button style */
    border: 0;
    display: inline-block;
    background: rgba(0, 0, 0, 0.7);
    padding: 8px;
    color: white;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
  }

.timeline-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    bottom: 50px;
}

.seeker-box {
    gap: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    width: auto;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

.seeker-time {
    display: inline-block;
    background: black;
    color: #bbb;
    padding: 6px 12px 4px;
    border-radius: 8px;
    font-size: 20px;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.timeline-box {
    position: relative;
    user-select: none;
}
.page {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #000000;
    color: #ffffff;
    padding-top: $header-size;

    &::after {
        height: 1px;
        margin-bottom: -1px;
        content: "";
        display: block;
    }
}

.matrix {
    margin: 20px;
}

.header-toolbar {
    margin-left: 20px;
}</style>

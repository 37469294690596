<template>
  <div class="page">
    <LoadingOverlay />
    <div class="stock">
      <img class="logo" src="@/assets/logo/main.svg" alt="Vidarex" />
    </div>
    <div class="login-box">
      <Form
        @submit="onSubmit"
        @invalidSubmit="onInvalidSubmit"
        :validation-schema="validationSchema"
        ref="form"
        v-slot="{ errors }"
      >
        <h1 class="header">{{ $t("register.signUp") }}</h1>
        <h2 class="subhead">{{ $t("register.form.enterDetails") }}</h2>
        <VInput
          name="firstName"
          :label="$t('register.form.firstName')"
          autocomplete="given-name"
        />
        <VInput
          name="lastName"
          :label="$t('register.form.lastName')"
          autocomplete="family-name"
        />
        <VInput
          name="companyName"
          :label="$t('register.form.companyName')"
          autocomplete="organization"
        />
        <VInput
          name="phoneNumber"
          :label="$t('register.form.phoneNumber')"
          placeholder="+36201234567"
          autocomplete="tel"
        />
        <VInput
          name="email"
          :value="invitedEmail"
          :disabled="invitedEmail != ''"
          type="email"
          :label="$t('register.form.emailAddress')"
          autocomplete="email"
        />
        <VInput
          name="password"
          type="password"
          :label="$t('register.form.password')"
          autocomplete="current-password"
        />
        <Field name="tos" v-slot="{ handleChange, value, errorMessage }">
          <Checkbox
            :modelValue="value"
            @update:modelValue="handleChange"
            :info="errorMessage"
            :infoType="errorMessage && 'error'"
          >
            {{ $t("register.agree") }}
            <a
              target="_blank"
              href="https://vidarex.com/Vidarex_Privacy_Policy_HU.pdf"
              >{{ $t("register.terms") }}</a
            >
            {{ $t("register.and") }}
            <a
              target="_blank"
              href="https://vidarex.com/Vidarex_Privacy_Policy_HU.pdf"
              >{{ $t("register.privacy") }}</a
            >
          </Checkbox>
        </Field>
        <div>
          <span class="info error" v-html="errors.other"></span>
          <Button :text="$t('register.form.signUp')" type="submit" />
        </div>
        <div>
          <Button
            theme="secondary"
            :text="$t('register.form.backToLogin')"
            @click="$router.push('login')"
          />
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Logger from "@/utils/logger.js";
import Button from "@/components/Button.vue";
// import Input from "@/components/Input.vue";
import VInput from "@/components/VInput.vue";
import Checkbox from "@/components/Checkbox.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { Field, Form } from "vee-validate";
import { mapActions, mapState } from "vuex";
import { useI18n } from "vue-i18n";

// + es legalabb 4db szam
// kiirni hibat telefonszam

export default {
  name: "VRegistration",
  components: {
    Button,
    // Input,
    VInput,
    Checkbox,
    LoadingOverlay,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapState("account", {
      validationSchema: "registrationValidationSchema",
    }),
  },
  data() {
    return {
      invitedEmail: this.$route.query["email"] ?? "",
      otherError: "",
    };
  },
  mounted() {
    this.invitedEmail = this.email = this.invitedEmail;
  },
  methods: {
    ...mapActions("account", { createUser: "createUser" }),
    async onSubmit(data) {
      if (data.phoneNumber === "") {
        data.phoneNumber = null;
      }
      this.$store.dispatch("setLoading", true);

      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");

      this.createUser({ ...data, token })
        .then((result) => {
          //TODO: proper handling of result
          Logger.log(result);
          this.$router.push({ name: "RegistrationEnd" });
        })
        .catch((error) => {
          const remoteMessage = error.response.data;

          if (remoteMessage?.type === "VALIDATION_ERROR") {
            this.$refs.form.setFieldError(
              "phoneNumber",
              remoteMessage.extra.phoneNumber
            );
            this.$refs.form.setFieldError(
              "email",
              remoteMessage.extra.emailAddress
            );
          }
          const email = "support@vidarex.com";
          const link = `<a href='mailto:${email}'>${email}</a>`;
          const generalErrorMessage = this.t("register.sysErr").concat(
            `${link}`
          );

          if (remoteMessage?.type === "GENERAL_ERROR") {
            this.$refs.form.setFieldError("other", generalErrorMessage);
          } else {
            //eslint-disable-next-line no-prototype-builtins
            if (remoteMessage.hasOwnProperty("detail")) {
              this.$refs.form.setFieldError(
                "other",
                remoteMessage?.detail.message
              );
            } else {
              this.$refs.form.setFieldError("other", generalErrorMessage);
            }
          }
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
    scrollToError(form) {
      const firstErrorElement = form.querySelector(".error");
      const firstErrorInput = form.querySelector("input.error");
      firstErrorElement.scrollIntoView();
      firstErrorInput.focus();
    },
    onInvalidSubmit(event) {
      //TODO: set focus on the first error
      this.scrollToError(this.$refs.form.$el);
      Logger.log(event.errors);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.logo {
  position: fixed;
  margin: 50px;
  display: none;
  @include for-tablet-up {
    display: block;
  }
}

.separator {
  height: 1px;
  border: 0;
  background: #1f211c;
  opacity: 0.1;
  margin: 40px 0;
}

.page {
  display: flex;
  height: 100%;
  background-color: white;
}

.stock {
  background-image: url("../assets/images/promos/login_bg_2.jpg");
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  @include for-tablet-up {
    width: 4 * $col;
  }
}
.content-box,
.login-box {
  width: 10 * $col;
  @include for-tablet-up {
    width: 6 * $col;
  }
  @include for-desktop-up {
    width: 4 * $col;
  }
  height: 80%;
  max-width: 500px;
  margin: auto;
}

.header {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.subheader {
  font-family: "MorebiRounded";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.forgot_link {
  display: block;
  text-align: right;
}
.info.error {
  display: block;
  color: #ff6565;
  margin: 20px 0 0 0;
}
</style>

import Activation from "@/pages/Activation"
import Login from "@/pages/Login"
import Registration from "@/pages/Registration"
import RegistrationEnd from "@/pages/RegistrationEnd"
import VerificationSuccess from "@/pages/VerificationSuccess"
import ChangePassword from "@/pages/ChangePassword"
import ResetPassword from "@/pages/ResetPassword"
import ResetEnd from "@/pages/ResetEnd"
import Matrix from "@/pages/Matrix"
import Camera from "@/pages/Camera"
import Control from "@/pages/Control"
import ControlNewView from "@/pages/ControlNewView"
import BodyCamPage from "@/pages/BodyCamPage"
import AnalyticClipPage from "@/pages/AnalyticClipPage"
import store from "@/store"
import { getPermissions } from "../services/apiv2"


// import { getScopes }  from "../services/apiv2"

export const ROUTE_NAMES = {
    "HOME": 'Home',
    "CAMERA": 'Camera',
    "LOGIN": 'Login',
    "REGISTRATION": 'Registration',
    "ACTIVATION": 'Activation',
    "REGISTRATION_END": 'RegistrationEnd',
    "VERIFICATION_SUCCESS": 'VerificationSuccess',
    "RESET_PASSWORD": 'ResetPassword',
    "RESET_END": 'ResetEnd',
    "CHANGE_PASSWORD": 'ChangePassword',
    "MATRIX": "Matrix",
    "CONTROL": "Control",
    "NOT_FOUND": "NotFound",
    "BODYCAM": "BodyCamPage",
    "ANALYTIC": "AnalyticClipPage",
}

const loadIfNeeded = async () => {
    if (store.state.camera.cameras.length === 0) {
        store.dispatch("setLoading", true);
        await store.dispatch("camera/loadByScope", store.state.account.token);
        await store.dispatch("camera/getArchivedCameras");
        store.dispatch("setLoading", false);
    }
}

export default [
    {
        name: ROUTE_NAMES.HOME,
        path: '/',
        beforeEnter: async () => {
            if (await store.dispatch("isLoggedIn")){
                await loadIfNeeded()
                try {
                    await store.dispatch("account/acceptAllInvitaion")
                } catch(error){
                    console.error(error)
                }
                if (store.state.camera.cameras.length === 1)
                    return { name: ROUTE_NAMES.CAMERA, params: { id: store.state.camera.cameras[0].id } }
                else 
                    return { name: ROUTE_NAMES.MATRIX }
            }
            else
                return { name: ROUTE_NAMES.LOGIN }
        },
    },
    {
        name: ROUTE_NAMES.LOGIN,
        path: '/login',
        component: Login,
    },
    {
        name: ROUTE_NAMES.BODYCAM,
        path: '/body',
        component: BodyCamPage,
        beforeEnter: async () => {
            await loadIfNeeded()
        },
    },
    {
        name: ROUTE_NAMES.ANALYTIC,
        path: '/analytic',
        component: AnalyticClipPage,
        beforeEnter: async () => {
            
        },
    },
    {
        name: ROUTE_NAMES.REGISTRATION,
        path: '/registration',
        component: Registration,
    },
    {
        name: ROUTE_NAMES.ACTIVATION,
        path: '/activate',
        component: Activation,
    },
    {
        name: ROUTE_NAMES.REGISTRATION_END,
        path: '/thankyou',
        component: RegistrationEnd,
    },
    {
        name: ROUTE_NAMES.VERIFICATION_SUCCESS,
        path: '/verification',
        component: VerificationSuccess,
    },
    {
        name: ROUTE_NAMES.RESET_PASSWORD,
        path: '/resetpassword',
        component: ResetPassword,
    },
    {
        name: ROUTE_NAMES.RESET_END,
        path: '/resetend',
        component: ResetEnd,
    },
    {
        name: ROUTE_NAMES.CHANGE_PASSWORD,
        path: '/changepassword',
        component: ChangePassword,
    },
    {
        name: ROUTE_NAMES.MATRIX,
        path: '/matrix',
        meta: {
            scrollPos: {
                top: 0,
                left: 0
            }
        },
        component: Matrix,
        beforeEnter: async () => {
            store.dispatch("setLoading", true);
            await store.commit("camera/clearArchivedTimelineFromCurrentCamera")
            if (!store.state.camera.cameras.length){
                await Promise.allSettled([
                    store.dispatch("camera/loadByScope", store.state.account.token),
                ])
                await store.dispatch("camera/getArchivedCameras");
            }
            store.dispatch("setLoading", false);
            // if (!await isLoggedIn())
            // return { name: 'Login' }
        },
    },
    {
        name: ROUTE_NAMES.CAMERA,
        path: '/camera/:id',
        component: Camera,
        props: true,
        beforeEnter: async (to) => {
            await loadIfNeeded()
            store.commit("camera/setCurrentCamera", to.params.id)            
            to.meta.onlyLive = true
            to.meta.isAdmin = false
            try {
                const permissions = await getPermissions()
                if (permissions.find(x => x.resourceId === to.params.id && x.permission === 'Stream:archive:view'))
                    to.meta.onlyLive = false
                if (permissions.find(x => x.resourceId === store.state.camera.currentCamera.site.scopeId && x.permission === 'Scope:admin')){
                    to.meta.isAdmin = true
                }
            } catch(error){
                console.error(error)
            }
        },
    }, {
        path: '/camera/:id/embed',
        component: Camera,
        props: true,
        meta: {
            hideUI: true,
        },
        beforeEnter: async (to, from, next) => {
            if (to.query.email && to.query.password) {
                try {
                    await store
                    .dispatch("account/login", {
                        email: to.query.email,
                        password: to.query.password,
                        persistent: false,
                    })
                    await store.dispatch("camera/loadByScope", store.state.account.token);
                    store.commit("camera/setCurrentCamera", to.params.id)  
                    const newTo = {...to, query: {...to.query, email: undefined, password: undefined}}
                    return next(newTo)
                } catch(error){
                    console.error("Auth failed")
                }
            }

            return next()
            // if (!await isLoggedIn())
            // return { name: 'Login' }
        },
    },
    {
        path: '/camera/:id/:timestamp',
        component: Camera,
        props: true,
        beforeEnter: async (to) => {
            // if (!await isLoggedIn())
            // return { name: 'Login' }
            await loadIfNeeded()
            store.commit("camera/setCurrentCamera", to.params.id)
        },
    },
    {
        name: ROUTE_NAMES.CHANGE_PASSWORD,
        path: '/changepassword',
        component: ChangePassword,
    },
    // {
    //     name: ROUTE_NAMES.CONTROL,
    //     path: '/control',
    //     component: Control,
    //     props: true,
    //     beforeEnter: async () => {
    //         if (store.state.camera.cameras.length) return true
    //         store.dispatch("setLoading", true);
    //         await store.dispatch("camera/loadByScope", store.state.account.token);
    //         // const scopes = await store.dispatch("account/getScopes");
    //         // await store.dispatch("camera/loadByScope", scopes[0]);
    //         // await store.dispatch("camera/loadCameraSites");
    //         store.dispatch("setLoading", false);
    //         // if (!await isLoggedIn())
    //         // return { name: 'Login' }
    //       },
    // },
    {
        name: ROUTE_NAMES.CONTROLNEWVIEW,
        path: '/control/new',
        component: ControlNewView,

    },
    {
        name: ROUTE_NAMES.CONTROL,
        path: '/control/:viewId?',
        props: true,
        component: Control,
        beforeEnter: async (from, to, next) => {
            await loadIfNeeded()
            await store.dispatch("control/getControlRooms");
            if (!from.params.viewId && store.state.control.controlRooms[0]?.id){
                return next({
                    name: ROUTE_NAMES.CONTROL,
                    params: {viewId: store.state.control.controlRooms[0]?.id}
                })
            }
            try {
                if (from.params.viewId)
                    await store.dispatch("control/getControlRoomsId", from.params.viewId);
            } catch(error){
                console.log(error)
                if (store.state.control.controlRooms[0]?.id)
                    return next({
                        name: ROUTE_NAMES.CONTROL,
                        params: {viewId: store.state.control.controlRooms[0].id}
                    })
            }
            next()
        }
    },
    {
        name: ROUTE_NAMES.NOT_FOUND,
        path: '/:catchAll(.*)',
        redirect: '/'
    },
]

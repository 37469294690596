import Logger from "@/utils/logger.js"
import { getSites } from '../../services/apiv2'

export default {
    namespaced: true,
    state: () => ({
        sites: [],
    }),
    mutations: {
        setSites(state, data) {
            state.sites = data;
        },
    },
    actions: {
        async load({ commit }) {
            const camSites = await getSites()
            console.log(camSites)
            commit("setSites", camSites)
            return camSites
        },
        async loadSitesByCode({ commit, rootState, rootGetters }, code) {
            // rootState.API.get
            // commit("setsites", )

            const userSitesResponse = await rootState.API.getSitesByCode(
                rootGetters.vvs,
                code
            );
            // let cams = []
            //
            if (userSitesResponse.status !== 200) return userSitesResponse;
            const userSites = userSitesResponse.data;

            if (userSites.sites === "*") {
                // (await rootState.API.getSites(rootGetters.vvs)).data.forEach(site => {
                //     cams.push(rootState.API.getSiteCameras(rootGetters.vvs, site.id))
                // });
                commit("setSites", (await rootState.API.getSites(rootGetters.vvs)).data);
            } else {
                // userSites.sites.split(",").forEach(site => {
                //     cams.push(rootState.API.getSiteCameras(rootGetters.vvs, site))
                // });
                commit(
                    "setSites",
                    userSites.sites.split(",").map((s) => ({
                        id: s,
                    }))
                );
                Logger.log(userSites.sites.split(","));
            }
        },
    },
};

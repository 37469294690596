import Axios from 'axios'
import store from '../store'
const { VUE_APP_CLOUD_API_ROOT } = process.env

const baseConfig = {
    baseURL: VUE_APP_CLOUD_API_ROOT,
    headers: {
      'Content-Encoding': 'UTF-8',
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    },
    // paramsSerializer: (param) => qs.stringify(param, { indices: false }),
  }

const http = Axios.create(baseConfig)

http.interceptors.response.use(response => {
  if (response?.headers?.authorization) {
    store.dispatch('account/setToken', response?.headers?.authorization?.substring(7))
  }
  return response
}, (error) => {
  if (error.response?.status === 401) {
    store.dispatch('logout')
  } else {
    throw error
  }
  return error
})
http.interceptors.response.use(response => response.data)

http.interceptors.request.use(
  async (requestConfig) => {
    // Do something before request is sent
    /** Example on how to add authorization based on security */

    if(!requestConfig.noSecurity){
      // if ( !Array.isArray(security) || Array.isArray(security?.[0]?.bearerAuth) ) { // Not public or explicit specified on path
        if (!requestConfig.headers) requestConfig.headers = {}
        if (store.state.account.token) requestConfig.headers.Authorization = `Bearer ${store.state.account.token}`
      // }
    }

    return requestConfig
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  },
)

export const postAuth = (body) => {
  // {
  //     emailAddress: "string",
  //     password: "string",
  // }
  return http.post("/api/auth", body, {noSecurity: true})
}

export const getAuth = () => {
  return http.get("/api/auth")
}

export const getScopes = () => {
  return http.get("/api/scopes")
}

export const getSites = () => {
  return http.get("/api/sites")
}

export const getCameras = () => {
  return http.get("/api/cameras")
}

export const postRegistrationSignUp = (body) => {
  return http.post("/api/registration/sign-up", body, {noSecurity: true})
}

export const postProfileForgottenPasswordSendEmail = (body) => {
  return http.post("/api/profile/forgotten-password/send-email", body, {noSecurity: true})
}

export const postProfileForgottenPasswordChange = (body) => {
  return http.post("/api/profile/forgotten-password/change", body, {noSecurity: true})
}

export const postRegistrationActivateActivationCode = (activationCode) => {
  return http.post("/api/registration/activate/" + activationCode, null, {noSecurity: true})
}

export const getProfileInvitations = () => {
  return http.get(`/api/profile/invitations`)
}

export const postProfileInvitationsScopeIdAccept = (scopeId) => {
  return http.post(`/api/profile/invitations/${scopeId}/accept`)
}

export const postControlRooms = (body) => {
  return http.post(`/api/control-rooms`, body)
}

export const getControlRooms = () => {
  return http.get(`/api/control-rooms`)
}
export const getControlRoomsId = (viewId) => {
  return http.get(`/api/control-rooms/${viewId}`)
}

export const deleteControlRoomsId = (viewId) => {
  return http.delete(`/api/control-rooms/${viewId}`)
}

export const putControlRoomsIdCellsIndexCamera = (viewId, cellIndex, cameraId) => {
  return http.put(`/api/control-rooms/${viewId}/cells/${cellIndex}/camera`, {
    cameraId
  })
}

export const getCamerasCameraIdAlarms = (cameraId) => {
  return http.get(`/api/cameras/${cameraId}/alarms`)
}

export const postCamerasCameraIdAlarms = (cameraId, body) => {
  return http.post(`/api/cameras/${cameraId}/alarms`, body)
}

export const getAlarmsAlarmId = (alarmId) => {
  return http.post(`/api/alarms/${alarmId}`)
}

export const deleteAlarmsAlarmId = (alarmId) => {
  return http.delete(`/api/alarms/${alarmId}`)
}

export const patchAlarmsAlarmId = (alarmId) => {
  return http.patch(`/api/alarms/${alarmId}`)
}

export const getSitesSiteIdAlarms = (siteId) => {
  return http.get(`/api/sites/${siteId}/alarms`)
}

export const getScopeTagsTagIdAlarms = (tagId) => {
  return http.get(`/api/scope-tags/${tagId}/alarms`)
}

export const postAuthApiKey = (body) => {
  return http.post(`/api/auth/api-key`, body, { noSecurity: true })
}

export const getPermissions = (body) => {
  return http.get(`/api/profile/permissions`, {params: body})
}
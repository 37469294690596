//FIXME this file should only contain api configuration stuff
import axios from "axios";
import Logger from "@/utils/logger.js"

const { VUE_APP_VVS_API_ROOT, VUE_APP_DEVICE_API_ROOT, VUE_APP_AUTH_API_ROOT, VUE_APP_USERS_API_ROOT, VUE_APP_SITE_API_ROOT, VUE_APP_ID } = process.env;

const SERVER_ERROR_MAP = {
    500: { other: "Unknown error!" }, // UNKNOWN

    10000: { other: "Wrong email or password!" }, // INVALID_USER_OR_PASSWORD
    10001: { other: "This email address is already being used!" }, // INVALID_AUTHENTICATION_METHOD
    10002: { email: "Please confirm your email address!" }, // INVALID_USER_PASSWORD_STATE

    12000: { email: "This email address is already being used!" }, // ALREADY_EXISTS
};

//TODO: implement axios error handler -> axios.post(data).catch(axiosErrorHandler).catch(errorWrapper).then(responseParser)
/*
const axiosErrorHandler = () => {
    ...
};
*/
const errorWrapper = (error) => {
    console.log(error)
    throw { other: error.message, status: error.response.status };
};

const responseParser = ({ data }) => {
    if (data.error) {
        if (SERVER_ERROR_MAP[data.error.code] !== undefined) {
            throw SERVER_ERROR_MAP[data.error.code];
        } else {
            throw { other: `Unknown error! (${data.error.code})` };
        }
    }
    if (!data.result) throw { other: "Server error!" }; // Malformed response
    return data.result;
};

export default {
    createUser({
        firstName,
        lastName,
        email,
        password,
        companyName,
        phoneNumber,
        token,
    }) {
        return axios
            .post(`${VUE_APP_USERS_API_ROOT}/users/register`, {
                firstName,
                lastName,
                email,
                password,
                companyName,
                phoneNumber,
                robotChecking: {
                    'google.recaptcha.v3': {
                        token,
                    }
                }
            }, {
                headers: {
                    'x-application-id': VUE_APP_ID
                }
            })
            .then(responseParser);
        },
    renewUserToken() {
        return axios.put(`${VUE_APP_AUTH_API_ROOT}/authentication/renew`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getAccount({email, password}) {
        return axios.post(`${VUE_APP_AUTH_API_ROOT}/authentication/login/password`, {email, password})
            .catch(errorWrapper)
            .then(responseParser);
    },
    getAccountScopes(accountId) {
        if (!accountId) // Me
            return axios.get(`${VUE_APP_AUTH_API_ROOT}/administration/user/scopes`)
                .catch(errorWrapper)
                .then(responseParser);
    },
    getScopeDevices(scopeId) {
        return axios.get(`${VUE_APP_DEVICE_API_ROOT}/administration/device/scope/${scopeId}/device`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getDeviceMapping(deviceId) {
        return axios.get(`${VUE_APP_VVS_API_ROOT}/administration/instance/device/${deviceId}/mapping`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getDeviceVVS(instanceId) {
        return axios.get(`${VUE_APP_VVS_API_ROOT}/administration/instance/instance/${instanceId}/property/api.prefix`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getDeviceParent(scopeId, deviceId) {
        return axios.get(`${VUE_APP_DEVICE_API_ROOT}/administration/device/scope/${scopeId}/device/${deviceId}/parent`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getScopeSites(scopeId) {
        return axios.get(`${VUE_APP_SITE_API_ROOT}/administration/sites/scope/${scopeId}`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getScopeSitePath(scopeId, siteId) {
        return axios.get(`${VUE_APP_SITE_API_ROOT}/administration/sites/scope/${scopeId}/${siteId}/path`)
            .catch(errorWrapper)
            .then(responseParser);
    },
    getSitesByCode(vvs, code) {
        return axios.get(`https://${vvs}/user/${code}`);
    },
    getSites(vvs) {
        return axios.get(`https://${vvs}/sites`);
    },
    getServerTime(vvs) {
        return axios.get(`${vvs?.secure?'https':'http'}://${vvs?.host}:${vvs?.port}/api/server/time`);
    },
    getStreamInfo(videoInfo, durationsPromise) {
        const metaPromise = Promise.resolve({
            width: videoInfo.width,
            height: videoInfo.height,
            });

        return Promise.allSettled([
            metaPromise,
            durationsPromise,
        ])
    },
    getTimeline(host, cameraId, token) {
        return axios.get(`${host}api/cameras/${cameraId}/stat/durations/stored?token=${token}`)
    },
    getTimelines(host, token) {
        return axios.get(`${host}api/cameras/stat/durations/stored?includeCache=true&token=${token}`)
    },
    // getSites() {
    //     return axios.get(`${VUE_APP_SITE_API_ROOT}/administration/sites`)
    //         .catch(errorWrapper)
    //         .then(responseParser);
    // },
    getSiteCameras(vvs, siteID) {
        return axios.get(`https://${vvs}/sites/${siteID}/cameras`);
    },
    resetPassword({email}) {
        email
        // return axios.post(`${VUE_APP_AUTH_API_ROOT}/authentication/login/password`, {email, password})
        //     .catch(errorWrapper)
        //     .then(responseParser);
    },
    setRequestHeader(header, value, method = "post"){
        axios.defaults.headers[method][header] = value
        Logger.log(axios.defaults.headers)
    }
};

import WS from './websocketCommon.js';

export default class Websocket extends WS {
    async parseMessage(data){
        if (typeof data === "object") {
            let arrayBuffer = await data.arrayBuffer();
            
            if (data.length < 4) {
                return {id: 0, data: null};
            }
            
            const id = new DataView(arrayBuffer).getUint32();
            
            if (data.length <= 4) {
                return {id, data: null};
            }
            return {id, data: data.slice(4)};
        }
    }
}
<template>
   <div class="page">
      <Header>
         <div class="header-toolbar">
            <MatrixLiveSwitcher :bodycam="areArchivedCameras" :matrix="false" />
         </div>
         <div class="search-box">
            <Search :key="matrixSearch" :modelValue="tags" @update:modelValue="updateTags"
                    :suggestions="suggestions" />
         </div>
      </Header>
      <div class="search-panel">
         <div class="search-label-container">
            <label for="">Keresés az adott időszakban</label>
         </div>
         <div class="search-panel-in">
            <div class="calendar-container">
               <div>
                  <input v-model="fromDate" type="date" class="calendar-input">
               </div>
               <div>
                  <input v-model="toDate" type="date" class="calendar-input">
               </div>
               <div class="search-button-container">
                  <button @click="searchDate" class="search-button">Keresés</button>
               </div>
            </div>
            <div class="type-button-container">
               <button class="type-button" @click="gridView = !gridView">{{ gridView ? 'Lista Nézet' : 'Rács Nézet' }}</button>
            </div>
         </div>
      </div>
      <div v-if="!areArchivedCameras" class="no-body">
         You have no body cameras.
      </div>
      <div class="grid-days-container" v-if="gridView && areArchivedCameras">
         <div class="grid-days" v-for="day of groupedDays" :key="day.index">
            <div class="grid-day" :class="{ grey: !day.offDay }" v-if="day.offDay">

                  {{ `${day.dayOfMonth} ${day.dayOfWeekInNumber}., ${day.dayofWeek}` }}
            </div>
            <div v-else-if="offDayCalcFn(day)" :class="offDayCalcFn(day).isGrey ? 'grey-color' : 'off-day' ">
               {{ offDayCalcFn(day).dayValue }}
            </div>
            <div class="video-outer-container">
                  <span class="video-container" style="margin-right: 25px;" v-for="video of filteredVideos(day)" :key="video.index">
                     <Thumbnail
                        :videoObject="video"
                        :getImage="getImage"
                        :setPreviewPicture="setPreviewPicture"
                        :gottenId="video.index"
                        :metadataObject="{
                           gopWsUrl: video.gopWsUrl,
                           metadataWsUrl: video.metadataWsUrl,
                           metadataClassesUrl: video.metadataClassesUrl,
                           enabled: video.streamingEnabled
                        }"
                        :enableThumbnailStreaming="enableThumbnailStreaming"
                        @middleOfTimeline="setInitialVideoStartTime"
                        @click="handleSingleClick(video)"
                        @dblclick="handleDoubleClick(video)"
                        @mousedown="handleMouseDown(video)"
                        @mouseup="handleMouseUp(video)"
                     />
                  </span>
            </div>
         </div>
      </div>
      <div class="list-days-container" v-if="!gridView && areArchivedCameras">
         <BCPList :videoObject="videoObjectGroup" :listCameraNavigation="listCameraNavigation"/>
      </div>
   </div>
</template>

<script>
import Header from "../components/Header.vue";
import MatrixLiveSwitcher from "../components/MatrixLiveSwitcher";
import { Search } from "@vidarex-vsaas/client-component-library";
import { Thumbnail } from "@vidarex-vsaas/client-component-library";
import { mapState } from 'vuex'

export default {
   components: {
        Header,
        Search,
        MatrixLiveSwitcher,
        Thumbnail,
    },
    data() {
      return {
         lastSearch: {},
         videoObjectGroup: [],
         fromDate: 0,
         toDate: 0,
         days: [],
         groupedDays: [],
         daysOfTheWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
         ],
         monthsOfTheYear: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
         ],
         gridView: true,
         listedCameraGroup: [],
         initialVideoStart: {
            time: 0,
            locked: false
         },
         clickCount: 0,
         clickTimer: null,
         mouseIsHeld: false,
         lockTheMouse: false,
      }
    },
    methods: {
      enableThumbnailStreaming(video){
         for(let e of this.videoObjectGroup) {
            if (e.cameraId == video.cameraId && e.startDate == video.startDate && e.endDate == video.endDate ) {
               /* if (!e.listImage) e.listImage = picture
               e.streamingEnabled = true */
            }
         }
      },
      handleMouseDown(video){
         this.lockTheMouse = false
         this.mouseIsHeld = false
         video.streamingEnabled = false
         setTimeout(() => {
            this.mouseIsHeld = true
            video.streamingEnabled = true
         }, 500);
      },
      handleMouseUp(video){
         if (this.mouseIsHeld) {
            this.lockTheMouse = true
            video.streamingEnabled = false
         } else {
            this.lockTheMouse = false
         }
      },
      handleSingleClick(camera) {
         if (this.lockTheMouse) return;
         this.clickCount++
         if (this.clickCount == 1) {
         this.clickTimer = setTimeout(() => {
               this.clickTimer = null;
               this.initialVideoStart.locked = false
               this.gridCameraNavigation(camera)
               this.clickCount = 0
            }, 400);
         }
      },
      handleDoubleClick(camera) {
         clearTimeout(this.clickTimer);
         this.clickCount = 0
         this.clickTimer = null;
         this.initialVideoStart.locked = true
         this.gridCameraNavigation(camera)
      },
      setInitialVideoStartTime(input){
         this.initialVideoStart.time = input
      },
      setPreviewPicture(video, picture) {
         for(let e of this.videoObjectGroup) {
            if (e.cameraId == video.cameraId && e.startDate == video.startDate && e.endDate == video.endDate ) {
               if (!e.listImage) e.listImage = picture
            }
         }

      },
      offDayCalcFn(days) {
      let groupOfDays = [];
      let haveGrey = true;

      if (days && days.length > 0) {
         for (let day of days) {
            groupOfDays.push({
            dayOfMonth: day.dayOfMonth,
            dayOfWeekInNumber: day.dayOfWeekInNumber,
            dayofWeek: day.dayofWeek,
            isWeekDay: day.dayofWeek === 'Saturday' || day.dayofWeek === 'Sunday' ? false : true,
            });
         }

         groupOfDays.map((e) => (e.dayofWeek === 'Monday' || e.dayofWeek === 'Tuesday' || e.dayofWeek === 'Wednesday' || e.dayofWeek === 'Thursday' || e.dayofWeek === 'Friday') ? haveGrey = false : 'asd');
         if (groupOfDays.length == 1) {
            return {
            isGrey: haveGrey,
            dayValue: `${groupOfDays[0].dayOfMonth} ${groupOfDays[0].dayOfWeekInNumber}., ${groupOfDays[0].dayofWeek}`,
            };
         } else if (groupOfDays.length > 1) {
            return {
            isGrey: haveGrey,
            dayValue: `${groupOfDays[0].dayOfMonth} ${groupOfDays[0].dayOfWeekInNumber}., ${groupOfDays[0].dayofWeek} - ${groupOfDays[groupOfDays.length - 1].dayOfMonth} ${groupOfDays[groupOfDays.length - 1].dayOfWeekInNumber}., ${groupOfDays[groupOfDays.length - 1].dayofWeek}`,
            };
         } else {
            return { isGrey: haveGrey, dayValue: "No valid data in the input array" };
         }
      } else {
         return null
      }
      },
      dateTransformer(input) {
         if (String(input).length == 1) return "0" + input

         return input
      },
      async gridCameraNavigation(camera) {
         const cameraId = camera.cameraId
         const start = camera.startDate / 1000
         const end = camera.endDate / 1000
         const startTimestampToURL = start * 1000
         const cameraTimeStamp = [
            { length: 0, duration: start },
            {
               length: end - start,
               duration: end - start,
            },
         ]
         /* const serializedTimeStamp = JSON.stringify(cameraTimeStamp) */
         await this.$router.push({
            path: `/camera/${cameraId}/${startTimestampToURL}`
         })
         await this.$store.commit("camera/setArchivedTimelineToCurrentCamera", cameraTimeStamp)
         if (this.initialVideoStart.locked) {
            await this.$store.commit("camera/setInitialStartingTimeToCurrentCamera", this.initialVideoStart.time)
         } else {
            await this.$store.commit("camera/clearInitialStartingTimeOfCurrentCamera")
         }

      },
      async listCameraNavigation(camera) {
         const cameraId = camera.cameraId
         const start = camera.startDate.getTime() / 1000
         const end = camera.endDate.getTime() / 1000
         const startTimestampToURL = start * 1000
         const cameraTimeStamp = [
            { length: 0, duration: start },
            {
               length: end - start,
               duration: end - start,
            },
         ]
         /* const serializedTimeStamp = JSON.stringify(cameraTimeStamp) */
         await this.$router.push({
            path: `/camera/${cameraId}/${startTimestampToURL}`
         })

         await this.$store.commit("camera/setArchivedTimelineToCurrentCamera", cameraTimeStamp)
      },
      filteredVideos(day) {
         this.videoObjectGroup.forEach((video) => {
            if (video.date === day.dayId) {
               day.offDay = true
            }
         })
         return this.videoObjectGroup.filter((video) => video.date === day.dayId);
      },
      async getImage(id, cut) {
            try {
                const { image } = await this.$store.dispatch("camera/getCameraImage", {
                    index: this.$store.state.camera.cameras.findIndex(c => c.id === id),
                    time: cut,
                    options: {
                        timelineEnd: 5,
                        quality: 60,
                    }
                });
                return image
            } catch(error){
                throw new Error("Failed to load preview picture based on given timestamp", error)
            }
         },
      dateCalc(){
         this.days = []
            const oneDay = 24 * 60 * 60 * 1000;

            const fromTimestamp = new Date(this.fromDate).getTime();
            const toTimestamp = new Date(this.toDate).getTime();

            const differenceInDays = Math.round((toTimestamp - fromTimestamp) / oneDay);
            const locale = 'en-US';
            const options = { weekday: 'long' };

            for (let e = 0; e <= differenceInDays; e++) {
               const currentDateInMs = fromTimestamp + e * oneDay
               const currentDate = new Date(fromTimestamp + e * oneDay);
               const dayName = new Intl.DateTimeFormat(locale, options).format(currentDate);
               this.days.push(
                  {
                     dayofWeek: dayName,
                     dayId: `${currentDate.getFullYear()}/${currentDate.getMonth()+1}/${currentDate.getDate()}`,
                     offDay: false,
                     currentDateInMs: currentDateInMs,
                     dayOfMonth: this.monthsOfTheYear[currentDate.getMonth()],
                     dayOfWeekInNumber: currentDate.getDate()
                  }
               )
            }
            this.days.sort((a,b) => b.currentDateInMs - a.currentDateInMs)
            this.dayGrouper()
      },
      dayGrouper(){
         this.groupedDays = []
         const dummyGroup = []
         this.videoObjectGroup.map(video => {
            for(let day of this.days) {
               if (day.dayId == video.date ) {
                  if (!dummyGroup.includes(day.dayId)) {
                     dummyGroup.push(day.dayId)
                  }
               }
            }
         })
         let dummyGroup2 = []
         for(let [index, day] of this.days.entries()) {
            if (!dummyGroup.includes(day.dayId)) {
               dummyGroup2.push(day)
            } else {
               if (dummyGroup2.length != 0) this.groupedDays.push(dummyGroup2)
               this.groupedDays.push(day)
               dummyGroup2 = []
            }
            if (index === this.days.length - 1 && dummyGroup.length != 0) this.groupedDays.push(dummyGroup2)
         }
      },
      timeCalculator(input) {
         input.duration = {hours: 0, minutes: 0, seconds: 0}
         input.startDate = new Date(input.startDate)
         input.endDate = new Date(input.endDate)
         const durationTime = input.endDate.getTime() - input.startDate.getTime()
         input.duration.hours = Math.floor(durationTime / (1000 * 60 * 60));
         input.duration.minutes = Math.floor((durationTime % (1000 * 60 * 60)) / (1000 * 60))
         input.duration.seconds = Math.floor((durationTime % (1000 * 60)) / 1000);
      },
      async cameraLoader(){
         this.videoObjectGroup = []
         const allArchivedCameras = []
         this.$store.getters.filteredCameras.map(cam => cam.streams.map(stream => (stream.type == "ARCHIVE_ONLY") && allArchivedCameras.push(cam) ))
         console.log("videoObjectGroup", allArchivedCameras)
         const oneDayInMilisec = 86400000
         const fromTimestamp = new Date(this.fromDate).getTime()
         const toTimestamp = new Date(this.toDate).getTime() + oneDayInMilisec;
         for (let e of allArchivedCameras) {
            const durations = e.durations ? e.durations : []
            for (let k of durations) {
               if ( k.fromStamp > fromTimestamp && k.toStamp < toTimestamp) {
                  const videoObject = {
                     startDate: k.fromStamp,
                     endDate: k.toStamp,
                     dayOfWeek: this.daysOfTheWeek[new Date(k.fromStamp).getDay()],
                     date: `${new Date(k.fromStamp).getFullYear()}/${new Date(k.fromStamp).getMonth()+1}/${new Date(k.fromStamp).getDate()}`,
                     cameraId: e.id,
                     cameraName: e.tags[0].name,
                     listImage: 0,
                     streamingEnabled: false,
                     streamId: e.streams[0].id,
                     nodeHostName: e.streams[0].node.restApiConnection.hostname,
                     gopWsUrl: e.connections.gopWsUrl,
                     http: e.connections.http,
                     metadataClassesUrl: e.connections.metadataClassesUrl,
                     metadataWsUrl: e.connections.metadataWsUrl,
                  }
                  this.videoObjectGroup.push(videoObject)
               }
            }
         }
         for(let e of this.videoObjectGroup) {
               this.timeCalculator(e)
         }
         this.videoObjectGroup.sort((a, b) => b.startDate - a.startDate)
         // this.listPreviewImageLoader()
      },
         async listPreviewImageLoader() {
            for(let e of this.videoObjectGroup) {
               if (!e.listImage) {
                  const time = e.startDate.getTime()
                  try {
                     const newData = await this.getImage(e.cameraId, time)
                     const pic = URL.createObjectURL(newData)
                     e.listImage = pic
                  } catch (error) {
                     console.log(error)
                  }
               }
            }
         },
         async searchDate() {
            if (this.lastSearch &&
               this.lastSearch.fromDate === this.fromDate &&
               this.lastSearch.toDate === this.toDate &&
               this.lastSearch.filteredCameras === this.$store.getters.filteredCameras) {
               return null
            }
            if (!this.areArchivedCameras) return;
            if (this.fromDate && this.toDate) {
               if (this.fromDate > this.toDate) {
                  const earlier = this.fromDate
                  const later = this.toDate
                  this.fromDate = later
                  this.toDate = earlier
               }
               await this.cameraLoader()
               this.dateCalc()

               this.lastSearch = {
                  fromDate: this.fromDate,
                  toDate: this.toDate,
                  filteredCameras: this.$store.getters.filteredCameras
               }
            }
         },
         mountStart() {
            const oneDayInMilisec = 86400000 * 30
            this.fromDate = new Date(Date.now() - oneDayInMilisec)
            this.toDate = new Date(Date.now())
            this.searchDate()
            this.fromDate = this.fromDate.toISOString().split('T')[0];
            this.toDate = this.toDate.toISOString().split('T')[0];
         },
         updateTags(e) {
            this.$store.commit('camera/setTags', e)
            this.searchDate()
        },
    },
    computed: {
      ...mapState("camera", {
            tags: state => state.tags,
            areArchivedCameras: state => state.areArchivedCameras
        }),
        ...mapState("account", {
            token: state => state.token,
        }),
        activeCamera(){
            return this.$store.getters["camera/getCurrentCamera"]
        },
      suggestions() {
            if (this.$store.getters.filteredCameras.length === 1)
                return []


            return this.$store.getters.filteredCameras
                .map(c => c.tags)
                .flat()
                .filter(tag => !this.tags.map(t => t.value.name).includes(tag.name))
                .sort((a, b) => {
                    if (b.type - a.type === 0) {
                        if (a.level === undefined || a.level - b.level === 0) {
                            return a?.name?.localeCompare(b?.name)
                        }
                        return a.level - b.level
                    }
                    return b.type - a.type
                })
                .map(tag => ({ text: tag.name, value: tag }))
                .reduce((newArr, value) => {
                    if (!newArr.map(a => a.text).includes(value.text))
                        newArr.push(value)
                    return newArr
                }, [])
                .filter(
                    (tag) => {
                        return !this.$store.getters.filteredCameras.every(cam => cam.tags.map(t => t.name).includes(tag.text))
                    }
                )
            // .sort((a,b) => {
            //     return a.order - b.order
            //     // return a.text.localeCompare(b.text)
            // })
            // [
            //     // ...this.$store.getters.filteredCameras.filter(c => Array.isArray(c.site)).map(c => c.site).flat().map(s => s.name.defaultText),
            //     // ...this.$store.getters.filteredCameras.map(c => c.name)
            // ]

        }
    },
    async mounted() {
      this.mountStart()
    },
    watch: {
      gridView() {
         if(!this.gridView) this.listPreviewImageLoader()
      }
    }
}

</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

input[type="date"]::-webkit-calendar-picker-indicator {
    /* Set the color of the icon to white */
    filter: invert(1);
  }


.mediaplayer {
   width: 100px;
   height: 100px;
}
.search-panel {
   display:flex;
   margin-left: 100px;
   flex-direction: column;
   margin-top: 20px;
   margin-bottom: 30px;
}

.search-panel-in {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 90%;
}

search-label-container {
   font-size: 24px;
   margin-bottom: 5px;
}

.search-button-container {
   display: flex;
   align-items: center;
}

.search-button {
   background-color: cyan;
   color: black;
   padding: 10px 0px 10px 0px;
   border-radius: 50px;
   border: 0px;
   font-size: 20px;
   font-weight: 500;
   width: 150px;
}

.grid-days-container {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.no-body {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
}

table {
   border-radius: 20px;
   tr {
      background-color: #091730;
      color: white;
      padding: 10px;
   }
   td {
      background-color: #122a57;
      padding: 5px;
      color: white;
      text-align: center;
   }
}

.list-days-container {
   display: flex;
   justify-content: center;
}

.grey-color {
   color: grey;
   font-size: 30px;
   padding-bottom: 30px;
}

.off-day {
   color: rgb(255,181,181);
   padding-bottom: 30px;
   font-size: 30px;
}

.list-image {
   width: 100px;
}

.type-button-container {
   display: flex;
   align-items: center;
}

.type-button {
   background-color: #2f2f2f;
   padding: 13px 31px 13px 31px;
   color: rgb(175, 175, 175);
   font-size: 18px ;
   border: 0px;
   border-radius: 10px;
}

.calendar-container {
   display: flex;
   gap: 25px;
}
.grid-days {
   gap: 0px;
   width: 90%;
}

.video-outer-container {
   display: flex;
   flex-wrap: wrap;
}

.list-days {
   display: flex;
   flex-direction: column;
   gap: 0px;
   width: 85%;
   border-top: 1px solid grey;
}

.list-item {
   background-color: grey;
   margin-bottom: 10px;
   display: flex;
   flex-direction: column;
   padding: 5px;
   border-radius: 14px;
}

.list-item-container {
   margin-left: 50px;
}

.grid-day {
   padding-bottom: 20px;
   font-size: 30px;
   display: flex;
   align-items: start;
}

.list-day {
   padding-bottom: 30px;
   font-size: 30px;
   display: flex;
   align-items: start;
}

.grid-day.grey {
   padding-top: 10px;
   padding-bottom: 0px;
   display: flex;
   align-items: start;
   font-size: 30px;
}

.list-day.grey {
   padding-top: 10px;
   padding-bottom: 0px;
   display: flex;
   align-items: start;
   font-size: 20px;
   color: grey;
}

table {
   border-collapse: collapse;
   width: 80%;
 }

 table, th, td {
   border: 1px solid rgb(255, 255, 255);
 }

.video-container {
   padding-bottom: 30px;
   display: flex;
   width: fit-content;
}
.video {
   width: 300px;
   height: 220px;
   border: 2px solid grey;
   border-radius: 20px;
}

.calendar-input {
   color: white;
   background-color: #252525;
   padding: 13px;
   font-size: 18px;
   border: 0px;
   border-radius: 10px;
   width: 150px;
   height: 49xp;
}

.page {
   box-sizing: border-box;
   min-height: 100vh;
   background-color: #000c1a;
   color: #ffffff;
   padding-top: $header-size;

   &::after {
       height: 1px;
       margin-bottom: -1px;
       content: "";
       display: block;
   }
}

.search-box {
   width: 100%;
   max-width: 550px;
   margin: auto;
}

.header-toolbar {
   margin-left: 20px;
}

</style>

<template>
    <div class="page" ref="page">
        {{ $t("control.name") }}
        <input
            v-model="viewName"
            label=""
        />
        <br/>
        <GridSizeSelector :cols="10" :rows="10" v-model="gridSize"/>
        <br/>
        {{ $t("control.gridSize") }} {{ gridSize[0] }}x{{ gridSize[1] }}
        <br/>
        <Button @click="save">
            {{ $t("buttons.save") }}
        </Button>
    </div>
</template>

<script>
// import Logger from "@/utils/logger.js"
import { mapState } from 'vuex'
import Logger from "@/utils/logger.js"
import GridSizeSelector from '@/components/GridSizeSelector.vue'
// import Input from '@/components/Input.vue'
// import Button from '@/components/Button.vue'
import {Button} from "@vidarex-vsaas/client-component-library";

import { postControlRooms } from '../services/apiv2'
import { ROUTE_NAMES } from '../router/routes';

// const flat = (acc, cur)=>{
//     if (cur.children) cur.children.reduce(flat, acc)
//     acc.push(cur)
//     return acc
// }

export default {
    name: "VControl",
    data() {
        return {
            refreshTokenInterval: null,
            selected: null,
            gridSize: [0,0],
            selectCamOverlay: false,
            // tags: [],
            // cameras: [],
            views: [],
            viewName: ''
        }
    },
    async created( ){
        await this.$store.dispatch("site/load");
        Logger.log("sites", this.$store.state.site.sites)
        if (this.tags.length === 0)
            this.updateTags([this.suggestions[0]])
        // Logger.log("created")
    },
    mounted(){
        this.refreshTokenInterval = setInterval(() => {
            this.$store.dispatch("account/refreshToken")
        }, 1000*60*30);
        try {
            this.views = JSON.parse(localStorage.getItem('views')) ?? []

        } catch(error){
            this.views = []
        }
        // this.cameras.push(this.getLivePlaylist(0))
        // this.cameras.push(this.getLivePlaylist(9))
    },
    unmounted(){
        clearInterval(this.refreshTokenInterval)
    },
    props: ["id"],
    components: {
        GridSizeSelector,
        Button,
    },
    computed: {
        ...mapState( {
            tags: state => state.controlTags
        }),
        ...mapState('camera',{
            allCam: state => state.tags
        }),

        square(){
            return 4
            // return Math.ceil(Math.sqrt(Math.min(this.cameras?.length ?? 16, 16)))
        },
        // views(){
        //     // if (this.$store.state.site.sites !== undefined)
        //     //     return this.$store.state.site.sites.reduce(flat, []).filter(x => x.name.startsWith('CR_')).sort((a,b) => a?.name?.localeCompare(b?.name))
        //     // return []
        //     // try {
        //         return [[],[]]
        //         // return JSON.parse(localStorage.getItem('views')) ?? []
        //     // } catch(error) {
        //     //     return []
        //     // }
        // },
        gridCols(){
            if (!this.tags[0])
                return 4
            return Math.max(Math.min(this.suggestions.find(x => x.text === this.tags[0].text)?.value.cols ?? 4, 4), 1)
        },
        gridRows(){
            if (!this.tags[0])
                return 4
            return Math.max(Math.min(this.suggestions.find(x => x.text === this.tags[0].text)?.value.rows ?? 4, 4), 1)
            // let cams = []
            // this.$store.state.camera.cameras.forEach(c => {
            //     if (c.site.id === this.tags[0].value.id)
            //     cams.push(c)
            // })
            // return cams
        },
        cameras(){
            if (!this.tags[0])
                return []
            return this.suggestions.find(x => x.text === this.tags[0].text)?.value.cameras ?? []
            // let cams = []
            // this.$store.state.camera.cameras.forEach(c => {
            //     if (c.site.id === this.tags[0].value.id)
            //     cams.push(c)
            // })
            // return cams
        },
        camerasLivePlaylists(){
            return this.cameras.map(x => x?this.getLivePlaylist(x.id):'')
        },
        cameraSuggestions(){
            console.log("asdf", this.$store.getters.filteredCameras)
            if (this.$store.getters.cameras.length === 1)
                return []
            return this.$store.getters.cameras
                    .map(c => c.tags)
                    .flat()
                    .filter(tag => tag.type===0)

                    .sort((a,b) => {
                        if (b.type - a.type === 0){
                            if (a.level === undefined || a.level - b.level === 0){
                                return a?.name?.localeCompare(b?.name)
                            }
                            return a.level - b.level
                        }
                        return b.type - a.type
                    })
                    .map(tag => ({text: tag.name, value: tag}))
                    .reduce((newArr, value) => {
                        if (!newArr.map(a => a.text).includes(value.text))
                            newArr.push(value)
                        return newArr
                    },[])
                    // .filter(
                    //     (tag) => {
                    //         return !this.$store.getters.filteredCameras.every(cam => cam.tags.map(t => t.name).includes(tag.text))
                    //     }
                    // )
                    // .sort((a,b) => {
                    //     return a.order - b.order
                    //     // return a.text.localeCompare(b.text)
                    // })
                // [
                //     // ...this.$store.getters.filteredCameras.filter(c => Array.isArray(c.site)).map(c => c.site).flat().map(s => s.name.defaultText),
                //     // ...this.$store.getters.filteredCameras.map(c => c.name)
                // ]
        },
        suggestions(){
            return this.views.map((x) => ({
                text: x.name,
                value: x
            }))

        }
    },
    methods: {
        createNewView(){
            const name = prompt("Nézet neve:", '') ?? 'Névtelen'
            let cols = prompt("Oszlop:", '4') ?? 4
            let rows = prompt("Sor:", '4') ?? 4
            if (!Number.isInteger(Number(cols)))
                cols = 4
            if (!Number.isInteger(Number(rows)))
                rows = 4
            if (this.views.findIndex(x => x.name === name) !== -1)
                return alert("Ez a név már foglalt! Válassz másikat.")
                this.addView({name: name, cameras: [], rows, cols})
                this.tags[0] = this.suggestions[this.views.length-1]
        },
        addView(newItem){
            this.views.push(newItem)
            localStorage.setItem('views', JSON.stringify(this.views))
        },
        removeView(index){
            const tmp = [...this.views]
            tmp.splice(index, 1)
            this.views = tmp
            localStorage.setItem('views', JSON.stringify(this.views))
        },
        async updateTags (e) {
            e = e.filter(Boolean)
            if (e.length === 0){
                this.removeView(this.views.findIndex(x => x.name === this.tags[0].text))
                if (this.suggestions.length){
                    this.tags[0] = this.suggestions[this.suggestions.length-1] ?? null
                }
                else
                    this.$store.state.controlTags = []

                return
            }
            this.tags[0] = e.pop()
            // this.tags.push(e)
            this.$refs.searchComponent.inputBlur()
            // if (e.length){
            //     this.cameras[this.selected] = (
            //         await this.$store.dispatch('camera/getLivePlaylist', {camera: this.$store.state.camera.cameras.find(c => c.name === e[0].text), token: this.$store.state.account.token})
            //     )
            //     this.selectView(null)

            // }

        },

        async updateCameras (e) {

            if (e.length){
                // this.cameras[this.selected] = (
                //     await this.$store.dispatch('camera/getLivePlaylist', {camera: this.$store.state.camera.cameras.find(c => c.name === e[0].text), token: this.$store.state.account.token})
                // )
                this.cameras[this.selected] = this.$store.state.camera.cameras.find(c => c.name === e[0].text)
                localStorage.setItem('views', JSON.stringify(this.views))
                this.selectView(null)
                this.$refs.viewport.querySelector(".viewport .active").classList.remove("active")
                this.selectCamOverlay = false
                // localStorage.setItem('views', this.views)
            }

        },

        // getLivePlaylist(id) {
        //     // const active = this.$store.getters.cameras.find((c) => c.id === this.id);
        //     const active = this.$store.getters.cameras[id]
        //     return`${active.connections.http}api/playlist/${active.id}/playlist.m3u8?token=` + this.$store.state.account.token
        // },
        getLivePlaylist(id) {
            const active = this.$store.getters.cameras.find((c) => c.id === id);
            if (active)
                return`${active.connections.http}api/playlist/${active.id}/playlist.m3u8?token=` + this.$store.state.account.token
            return ''
        },
        toggleFullScreen(){
            this.$refs.viewport.requestFullscreen()
        },
        selectView(index){
            if (this.cameras[index])
            this.$router.push('/camera/' + this.cameras[index].id)
        },
        addCameraToView(index){
            this.selectCamOverlay = true
            if (this.$refs.viewport.querySelector(".viewport .active"))
                this.$refs.viewport.querySelector(".viewport .active").classList.remove("active")
            if (this.selected === index || index === null)
                return this.selected= null
            this.selected = index

            //TODO: add setFocus capability to the component
            setTimeout(()=>{
                this.$refs.cam_search.querySelector("input").focus()
            },0)
            this.$refs.viewport.querySelectorAll(".viewport")[this.selected].classList.add("active")
        },
        async save(){
            postControlRooms
            const viewId = {id: "33a75cd3-fd6e-4d16-808c-628653f8e7a2"}
            this.$router.push({ name: ROUTE_NAMES.CONTROLVIEW, params:  {viewId: viewId.id}})
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.search-box {
    width: 100%;
    max-width: 600px;
    margin: auto;
}
.viewport {
    width: 100%;
}
.header-toolbar {
    margin-left: 20px;
}
.viewport::v-deep .active {
    border-color: #00318d;
}
.page {
    box-sizing: border-box;
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
    padding-top: $header-size;
    overflow: hidden;
    display: block;
    align-items: stretch;
    &::after {
        height: 1px;
        margin-bottom: -1px;
        content: "";
        display: block;
    }
}

.menu-top {
    font-size: 14px;
    font-weight: 700;
    margin-left: auto;
}

.container {
    max-width: $container-width;
    margin: auto;
}

.video-screen {
    width: 100%;
    height: 60vh;
    position: absolute;
    overflow: hidden;
    z-index: 0;
}

.controls {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    margin: auto auto 0;
}

.controls-group {
    display: flex;
    align-items: center;
    &:first-child,
    &:last-child {
        flex: 1;
    }
    &:last-child {
        justify-content: end;
    }
}

.sign-out {
    color: #00a0b6;
    font-weight: 900;
    margin-left: 10px;
}

.section-player {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    height: calc(100vh - 0px);
    padding: 0 40px;
}

.video-player {
    width: 100%;
    height: calc(100vh - 40px);
    height: calc(100vh - 0px);
    margin-top: $header-size * -1;
    padding-top: $header-size;
    box-sizing: border-box;
}

.icon {
    width: 24px;
    height: 24px;
}
#my-video {
    width: 100%;
    height: 100%;
}
.section-matrix {
    background-color: #222222;
    padding: 40px;
}
.add {
    background-color: #222;
}

.overlay {
    color: white;
    background-color: #000000cc;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
}
</style>

<template>
    <div class="grid-size-selector">
        <div  v-for="(y, iy) of Array(rows)" :key="iy" class="row">
            <div :class="{highlight: iy<=hoverIndex/cols && ix<=hoverIndex%cols, selected: selectedIndex !== null && iy<=selectedIndex/cols && ix<=selectedIndex%cols}" v-for="(x, ix) of Array(cols)" :key="ix" class="cell" @mouseenter="hover(cols*iy+ix)" @click="select(cols*iy+ix)"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VHeader",
    props: {
        cols: {
            default: 4
        },
        rows: {
            default: 4
        },
        modelValue: {
            default: null
        }
    },
    data(){
        return {
            hoverIndex: 0,
            selectedIndex: this.modelValue === null?null:(this.modelValue[1]-1)*this.cols+(this.modelValue[0]-1)
        }
    },
    methods: {
        select(index){
            this.selectedIndex = index
            this.$emit("update:modelValue", [
                index%this.cols+1,
                Math.floor(index/this.cols)+1,
            ])
        },
        hover(index){
            this.hoverIndex = index
        }
    },
    watch: {
        modelValue(newData) {
            this.selectedIndex = newData === null?null:(newData[1]-1)*this.cols+(newData[0]-1)
        }
    }
}
</script>

<style scoped>
.row {
    display: flex;
    object-fit: contain;
    justify-content: center;
}
.row .cell{
    background-color: black;
    width:15px;
    height:15px;
    border: 1px solid #666;
    margin-right: -1px;
    margin-bottom: -1px;
}
.grid-size-selector:hover .cell.highlight {
    border-color: #00A0B6;
    z-index: 3;
}
.grid-size-selector .cell.selected {
    background-color: #555;
    border-color: white;
    z-index: 2;
}
.grid-size-selector{
    display: block;
    cursor: pointer;
    margin: 30px 0;
}
</style>

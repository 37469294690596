<template>
    <span class="info" :class="type"><slot /></span>
</template>

<script>
export default {
    name: "VInfo",
    props: ["type"],
};
</script>

<style scoped>
.info {
    display: block;
    margin: 20px 0 0 0;
}
.info.error {
    color: #ff6565;
}
.info.success {
    color: #00C999;
}
.info.error-block {
    background-color: #ff6565;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
}
.info.success-block {
    background-color: #00C999;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
}
</style>

<template>
    <Blob2Url :getImage="getImages">
        <template v-slot:default="slotProps">
            <CameraGrid
                ref="cameragrid"
                class="matrix"
                @loaded="slotProps.loaded"
                @open="routerSetupFunction($router, $event)"
                :getImages="slotProps.getImages"
                :initialCameras="$store.getters.filteredCameras"
                :refreshInterval="4000"
                :isLive="isLive"
            />
        </template>
    </Blob2Url>
</template>

<script>
export default {
    name: "CameraGridBlob",
    data() {
        return {
            loadingImage: false,
        }
    },
    methods: {
        routerSetupFunction(router, event) {
                router.push('/camera/' + event.id)
                this.navigatoToCamera()
        },
        async navigatoToCamera(){
            await this.$store.commit("camera/setMatrixTimeline", this.timestamp)
        },
        refreshImageCaller(force){
            // Can you explain why ???
            // this.loadingImage = true
            // const previewFetchTs = this.timestamp
            this.$refs.cameragrid.refreshImageInChildComponent(force)
            // if (this.isSeaking && this.timestamp !== previewFetchTs) {
            //     this.refreshImageCaller()
            // } else {
                // this.loadingImage = false
            // }
        },
        getImages(cameras) {
            const targetWidth = Math.min(this.$refs?.cameragrid?.$el.children[0].offsetWidth ?? 480, 720)
            return cameras.map(async (camera) => {
                
                const index = this.$store.state.camera.cameras.findIndex(c => camera.id === c.id)
                // if (camera.streams[0].type === "ARCHIVE_ONLY") {
                // throw "Body worn camera.";
                // }
                    // if (this.$store.getters.filteredCameras[index]) this.$store.getters.filteredCameras[index].loading = true
                    const { image } = await this.$store.dispatch("camera/getCameraImage", { index, time: Math.floor(this.timestamp), options: {targetWidth, quality: 75, isMatrix:true} })
                    // if (this.$store.getters.filteredCameras[index])  this.$store.getters.filteredCameras[index].loading = false
                    return image
                });
            },
        },
        props: {
            timestamp: Number,
            isSeaking: Boolean,
            isLive: Boolean,
        },
        watch: {
            timestamp(){
                if (!this.isLive) {
                    this.refreshImageCaller()
                }
            },
            isSeaking(newValue){
                if (!newValue) {
                    this.refreshImageCaller(true)
                }
            },
        }
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";

.matrix {
    margin: 20px;
}
</style>
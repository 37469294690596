import { object as yupObject, string as yupString, boolean as yupBoolean } from "yup";
import API from '@/config/api.js'
import {
    getScopes,
    postAuth,
    postAuthApiKey,
    getAuth,
    postProfileForgottenPasswordChange,
    postProfileForgottenPasswordSendEmail,
    postRegistrationActivateActivationCode,
    getProfileInvitations,
    postProfileInvitationsScopeIdAccept,
    postRegistrationSignUp,
 } from '@/services/apiv2.js'

const initialState = () => ({
    hiddenFeatureEnabled: false,
    id: null,
    account: null,
    email: null,
    token: null,
    scopes: null,
    registrationValidationSchema: yupObject({
        firstName: yupString().required(),
        lastName: yupString().required(),
        email: yupString().required().email(),
        password: yupString().min(6).required(),
        phoneNumber: yupString()
        .test('phoneNumber', 'Not a valid phone number.', value => {
            if (value) {
                return value.match(/^\+\d{5,}$/g);
            }
            return true;
        }),
        tos: yupBoolean().isTrue().default(false),
    }),
    resetPasswordValidationSchema: yupObject({
        email: yupString().required().email(),
    }),
    changePasswordValidationSchema: yupObject({
        password: yupString().min(6).required(),
    }),
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
    },
    mutations: {
        setAccountData(state, data) {
            state.account = data;
        },
        setToken(state, newToken) {
            state.token = newToken;
        },
        setScopes(state, scopes) {
            state.scopes = scopes;
        },
        hiddenFeatureEnable(state) {
            state.hiddenFeatureEnabled = true;
        },
        resetState(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async isTokenValid(){
            // try {
                //FIXME:
                // const scopes = await API.getAccountScopes();
                // commit("setScopes", scopes);
                return true;
            // } catch(err){
            //     return false
            // }
        },
        setToken({ commit, rootState }, token) {
            rootState.storage.setItem("token", JSON.stringify(token));
            return commit("setToken", token)
        },
        async acceptAllInvitaion() {
            try {
                const invitations = await getProfileInvitations()
                await Promise.all(invitations.map(i => postProfileInvitationsScopeIdAccept(i.scopeId)))
            } catch(error){
                return false
            }
            return true
        },
        async activateUser(ctx, data) {
            return await postRegistrationActivateActivationCode(data);
        },
        async resetPassword(ctx, data) {
            return await postProfileForgottenPasswordChange({
                token: data.token,
                newPassword: data.password,
            })
        },
        async resetPasswordSendMail(ctx, data) {
            return await postProfileForgottenPasswordSendEmail({
                emailAddress: data.email
            });
        },
        async createUser(ctx, data) {
            return await postRegistrationSignUp({
                emailAddress: data.email,
                password: data.password,
                personName: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                },
                companyName: data.companyName,
                phoneNumber: data.phoneNumber,
                preferredLanguageCode: 'hu'
            })
        },
        async login(ctx, { email, password }) {
            const user = await postAuth({
                emailAddress: email,
                password: password
            })

            return user
        },
        async loginToken(ctx, body) {
            const user = await postAuthApiKey(body)
            return user
        },
        async refreshToken() {
            return await getAuth()
        },
        logout({ commit, rootState }) {
            commit("resetState");
            API.setRequestHeader("Authorization", undefined)
            rootState.storage.removeItem("token");
            rootState.storage.removeItem("account");
        },
        async loadScopes({ commit }) {
            const scopes = await getScopes()
            commit("setScopes", scopes);
            return scopes;
        },
        getScopes({state, dispatch}){
            if (state.scopes !== null)
                return state.scopes
            return dispatch("loadScopes")
        },
        restore({ rootState, commit, dispatch }) {
            try {
                const token= JSON.parse(rootState.storage.getItem("token"))
                if (!token) return
                commit("setToken", token);
                commit("setAccountData", JSON.parse(rootState.storage.getItem("account")));
            } catch(err) {
                dispatch('logout')
            }
        },
    },
};

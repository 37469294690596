<template>
  <div class="button-toggle">
      <Button :class="{disabled: !matrix, unclickable: matrix }" icon="grid" @click="jumpMatrix()" />
      <Button :class="{disabled: !control, unclickable: control }" @click="jumpLMV()">
        <span class="material-symbols-outlined">
          visibility
        </span>
      </Button>
      <Button v-show="bodycam" :class="{disabled: matrix || control }" icon="body" @click="jumpBodyCam()" />
  </div>
</template>

<script>
import { ROUTE_NAMES } from '../router/routes';

export default {
  name: "VButton",
  props: {
    text: String,
    theme: String,
    matrix: Boolean,
    control: Boolean,
    bodycam: Boolean,
  },
  methods: {
    jumpMatrix(){
      if (!this.matrix)
        this.$router.push({name: ROUTE_NAMES.MATRIX})
    },
    jumpLMV(){
        this.$router.push({name: ROUTE_NAMES.CONTROL})
    },
    jumpBodyCam(){
      this.$router.push({name: ROUTE_NAMES.BODYCAM})
    }
  },
};
</script>

<style scoped>
.button-toggle {
  position: relative;
  top: 5px;
  margin: 10px;
    display: inline-block;
    .btn {
      border-radius: 0px;
      height: 20px;
        &:first-child {
            border-radius: 10px 0 0 10px;
        }
        &:last-child {
            border-radius: 0 10px 10px 0;
        }
        display: inline-flex;
        background-color: #111;
        margin: 0;
    }

    .disabled.btn{
      background-color: #444;
    }
    .material-symbols-outlined {
        font-size: 16px;
    }
}

.unclickable {
  pointer-events: none;
}
</style>

import {default as routes, ROUTE_NAMES} from './routes'
import { createRouter, createWebHistory } from "vue-router";
import store from '../store';

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.name === ROUTE_NAMES.MATRIX && savedPosition || to.meta.scrollPos) {
            return savedPosition || to.meta.scrollPos
        } else {
            return { top: 0 }
        }
    },    
    routes,
})

router.beforeEach((to, from, next) => {
    if (from.meta?.scrollPos){
        from.meta.scrollPos.top = document.documentElement.scrollTop
    }
    return next()
})

router.beforeEach(async (to) => {
    const {apiKey, apiSecret} = to.query
    if (apiKey && apiSecret){
        try {
            await store.dispatch("account/loginToken", {
                apiKey,
                secret: apiSecret,
            })
        } catch(error) {
            console.error(error)
            return { name: ROUTE_NAMES.LOGIN }
        }
        return {...to, query: {...to.query, apiKey: undefined, apiSecret: undefined}, replace: true}
    }
})

router.beforeEach(async (to) => {
    const {wm} = to.query
    if (wm){
        sessionStorage.setItem("wm", wm)
        return {...to, query: {...to.query, wm: undefined}, replace: true}
    }
})

export default router
<template>
  <button :class="'button ' + theme">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    text: String,
    theme: String,
  },
  methods: {},
};
</script>

<style scoped>
.button {
  color: #ffffff;
  background: #132b58;
  border-radius: 200px;
  border: 1px solid transparent;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  min-height: 60px;
  min-width: 190px;
  padding: 20px;

  text-transform: uppercase;

  margin: 30px 0;
}
.secondary {
  background: #ffffff;
  border: 1px solid #121212;
  color: #121212;
}
</style>

import Axios from 'axios'
const { VUE_APP_METADATA_API_ROOT } = process.env

const baseConfig = {
    baseURL: VUE_APP_METADATA_API_ROOT,
    headers: {
      'Content-Encoding': 'UTF-8',
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    },
    // paramsSerializer: (param) => qs.stringify(param, { indices: false }),
  }

const http = Axios.create(baseConfig)

export const getQueries = ( cameraId, startDate, endDate, classes, roi, token) => {
  return http.get(`/cameras/${cameraId}/events?fromTsMs=${startDate}&toTsMs=${endDate}&classes=${classes}&roi=${roi.left},${roi.top},${roi.width},${roi.height}&token=${token}`)
}

export const getClasses = () => {
  return http.get(`/classes`)
}

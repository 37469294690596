import API from "@/config/api"
import Websocket from '@/services/websocket.web.js'
// import { WEBSOCKET_STATES } from '@/services/websocketCommon.js'
import { ImageSource } from "@/utils/image"
import isJpeg from "@/utils/isJpeg.js"
import Logger from "@/utils/logger.js"
import { getCameras, getSites } from '../../services/apiv2'

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

function createUrl(connection) {
    const { scheme, username, password, hostname, port, path } = connection
    const sPassword = password ? ':' + password : ''
    return `${scheme}://${username}${sPassword}${hostname}:${port}${path}`
}

const TAG_TYPES = {
    CAMERA_NAME:0,
    SITE_NAME:1,
}

function generateTags(camera){
    if (camera.site)
        return [{name: camera.name, type: TAG_TYPES.CAMERA_NAME}, ...camera.site.path.flat().map((s, i, arr) => ({name: s, type: TAG_TYPES.SITE_NAME, level: arr.length-i}))]
    else
        return [{name: camera.name, type: TAG_TYPES.CAMERA_NAME}]
}

const initialState = ()=>({
    tags: [],
    analyticClipTags: [],
    cameras: [],
    archivedCameras: [],
    areArchivedCameras: false,
    currentCamera: null,
    archivedTimestamp: 0,
    allAnalyticClips: [],
    clipTimeline: 0,
    matrixTimeline: 0,
    secondMatrixTimeline: 0,
})

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setTags(state, data){
            state.tags = data
        },
        setAnalyticClipTags(state, data){
            state.analyticClipTags = data
        },
        setCameras(state, data){
            state.cameras = data.map(record => ({...record, tags: generateTags(record)}))
            /* console.log(state.cameras) */
        },
        resetState(state) {
            Object.assign(state, initialState());
        },
        setCurrentCamera(state, inputCameraId) {
            state.currentCamera = state.cameras.find(camera => camera.id === inputCameraId)
        },
        clearCurrentCamera(state) {
            state.currentCamera = {}
        },
        clearArchivedTimelineFromCurrentCamera(state) {
            if (!state.currentCamera) return
            console.log(state.currentCamera.archivedTimestamp)
            state.currentCamera.archivedTimestamp = 0
        },
        setArchivedTimelineToCurrentCamera(state, inputTimestamp) {
            if (!state.currentCamera) return
            state.currentCamera.archivedTimestamp = inputTimestamp
            state.archivedTimestamp = inputTimestamp
        },
        setInitialStartingTimeToCurrentCamera(state, inputInitialStartingTime) {
            if (!state.currentCamera) return
            state.currentCamera.initialStartingTime = inputInitialStartingTime
        },
        setAllAnalyticClips(state, inputAnalyticClips) {
            state.allAnalyticClips = inputAnalyticClips
        },
        clearInitialStartingTimeOfCurrentCamera(state) {
            state.currentCamera.initialStartingTime = 0
        },
        setClipTimeline(state, newValue) {
            state.clipTimeline = newValue
        },
        setMatrixTimeline(state, inputTimestamp) {
            state.matrixTimeline = inputTimestamp
        },

    },
    getters: {
        // filteredCameras(){
        //     return this.cameras
        // }
        getCurrentCamera(state) {
            if (state.currentCamera) return state.currentCamera
        },
        getCurrentCameraArchivedTimeline(state) {
            return state.currentCamera?.archivedTimestamp
        },
        getClearArea(state) {
            return state.currentCamera.clearArea
        },
        getCurrentCameraInitialStartingTime(state) {
            return state.currentCamera?.initialStartingTime
        },
        getAnalyticClips(state) {
            return state.allAnalyticClips
        },
        getTags(state){
            return state.tags
        },
        getAnalyticClipTags(state){
            return state.analyticClipTags
        },
        getClipTimeline(state) {
            return state.clipTimeline
        },
        getCurrentMatrixTimeline(state) {
            return state.matrixTimeline
        }
    },
    actions: {
        isCurrentCameraArchived({ state }) {
            return state.currentCamera.streams.some(stream => stream.type === "ARCHIVE_ONLY")
        },
        reset({commit}){
            commit('resetState')
        },
        async loadCameraSites({ state, commit }){
            const parentPromises = state.cameras.map(async c => {
                return {
                    parent: await API.getDeviceParent(c?.scopeId, c?.deviceId),
                    ...c
                }
            })

            const cams = (await Promise.allSettled(parentPromises)).filter(m => m.status === "fulfilled").map(p => p.value)
            // const uniqueParentIds = [...new Set(cams.map(c => ({scopeId: c.scopeId, siteId: c.parent})))];

            // const path = (await Promise.allSettled(
            //     uniqueParentIds.map(async p => ({scopeId: p.scopeId, siteId: p.siteId, path: await API.getScopeSitePath( p.scopeId, p.siteId)}))
            // )).filter(m => m.status === "fulfilled").map(p => p.value)



            // const camsSites = (await Promise.allSettled(path)).filter(m => m.status === "fulfilled").map(p => p.value)
            const camSites = await getSites()
            /* console.log(camSites) */
            const sitePath = (sites, newSites = [], path = []) => {
                sites.forEach(site => {
                  const p = path.slice()
                  p.push(site.name)
                  newSites.push({
                    ...site,
                    path: p.slice(),
                  })
                  if (site.children)
                    sitePath(site.children, newSites, p.slice())
                })
                return newSites
              }

            // commit('setCameras', cams.map(c => ({...c, site: camsSites.find(p => p.siteId === c.parent).path})))
            commit('setCameras', cams.map(c => ({...c, site: sitePath(camSites).find(site => site.id === c.parentId)})))
            // const parentPromises = state.cameras.map(c => API.getDeviceParent(c?.scopeId, c?.deviceId))
            // const parents = (await Promise.allSettled(parentPromises)).filter(m => m.status === "fulfilled").map(p => p.value)
            // const uniqueParentIds = [...new Set(parents)];

            // uniqueParentIds.map(site => API.getScopeSitePath(c?.scopeId, site))

        },
        async getArchivedCameras({ state }) {
            state.cameras.map(cam => cam.streams.map(stream => stream.type === "ARCHIVE_ONLY" && state.archivedCameras.push(cam)));
            state.archivedCameras.length > 0 && (state.areArchivedCameras = true);
            return state.archivedCameras
        },
        async loadByScope({commit}, token){
            const response = await getCameras()
            if (!response) return
            let wsConnectionCollection = []

            const storageUrls = response.map(r => {
                if (!r.streams[0].node.restApiConnection) return null
                return createUrl(r.streams[0].node.restApiConnection)
            }).filter(url => url).filter(onlyUnique)
            const durationResults = await Promise.allSettled(storageUrls.map(url => API.getTimelines(url, token)))
            const durations = durationResults.filter(r=>r.value?.data).map(r=>r.value.data).flat()

            console.log("DURATIONS:", durations)

            const cams = response.map(r => {
                if (!r?.streams[0]?.node) return null
                if (!r?.streams[0]?.videoInfo) return null

                const durationRecords = durations.filter(d => d.streamId === r?.streams[0].id)[0]?.records?.map(d => {
                    return {
                        fromStamp: Date.parse(d.from),
                        toStamp: Date.parse(d.to)
                    }
                })

                const streamInfo = {
                    width: r?.streams[0]?.videoInfo.width,
                    height: r?.streams[0]?.videoInfo.height,
                    duration: !durationRecords || durationRecords.length === 0 ? undefined :
                        {
                            fromStamp: durationRecords[0].fromStamp,
                            toStamp: durationRecords[durationRecords.length - 1].toStamp,
                        }
                }

                const { scheme, username, password, hostname, port, path } = r.streams[0].node.restApiConnection
                const sPassword = password ? ':' + password : ''
                const url = `${scheme}://${username}${sPassword}${hostname}:${port}${path}`
                const wsUrl = `${scheme==='http'?'ws':'wss'}://${username}${sPassword}${hostname}:${port}${path}?token=${token}`
                const gopWsUrl = `${scheme==='http'?'ws':'wss'}://${username}${sPassword}${hostname}:${port}/gops?token=${token}`
                //FIXME: backend
                const metadataApiRoot = new URL(process.env.VUE_APP_METADATA_API_ROOT)
                const metadataWsUrl = `${metadataApiRoot.protocol==='http:'?'ws':'wss'}://${metadataApiRoot.host}/query?token=${token}`
                const metadataClassesUrl = `${metadataApiRoot.toString()}classes?token=${token}`
                if (wsConnectionCollection[wsUrl] === undefined)
                    wsConnectionCollection[wsUrl] = new Websocket(wsUrl)
                // let duration = undefined
                // if (r.streams[0].type === "ARCHIVE_ONLY"){
                //     duration = API.getTimeline(url, r.streams[0].id, token)
                // }
                return {
                    id: r.streams[0].id,
                    deviceId: r.id,
                    parentId: r.siteId,
                    scopeId: null, //FIXME
                    name: r.name,
                    streams: r.streams,
                    streamInfo: streamInfo,
                    durations: (r.streams[0].type === "ARCHIVE_ONLY") ? durationRecords : undefined,
                    clearArea: r.clearArea,
                    analysisEnabled: r.analysisEnabled,
                    connections: {
                        http: url,
                        ws: wsConnectionCollection[wsUrl],
                        gopWsUrl,
                        metadataWsUrl,
                        metadataClassesUrl,
                    }
                }
            }).filter(x => x !== null)
            const camSites = await getSites()
            /* console.log(camSites) */
            const sitePath = (sites, newSites = [], path = []) => {
                sites.forEach(site => {
                  const p = path.slice()
                  p.push(site.name)
                  newSites.push({
                    ...site,
                    path: p.slice(),
                  })
                  if (site.children)
                    sitePath(site.children, newSites, p.slice())
                })
                return newSites
              }
            commit('setCameras', cams.map(c => ({...c, site: sitePath(camSites).find(site => site.id === c.parentId)})))
            // commit('setCameras', cams)
            console.log("RAW RESPONSE:", response)
            console.log("CAMS:", cams)
            return cams
        },
        // async getCameraPlaylist({state}, {index, time}){
            //     const camera = state.cameras[index]
            //     const host = camera.connections.http
        //     const timePath = time===undefined?'':`/${time}`
        //     return `${host}/api/playback/${camera.site.id}/${camera.id}${timePath}/playlist.m3u8`

        // },
        getLivePlaylist(ctx,{camera, token}){
            return `${camera.connections.http}api/playlist/${camera.id}/playlist.m3u8?token=${token}`
        },
        async getCameraImage({state}, {index, time, crop = {left: 0, top: 0, width: 1, height: 1}, options}){
            Logger.log("getCameraImage Start: ", index)
            // if (state.cameras[index].connections.ws.state() !== WEBSOCKET_STATES.OPEN){
            //     Logger.log("getCameraImage open")
            //     await state.cameras[index].connections.ws.open()
            //     Logger.log("getCameraImage opened")
            // }
            try {
                const {id, data} = await state.cameras[index].connections.ws.sendAsync(
                    {
                        cameraId: state.cameras[index].id,
                        action: "image",
                        timestamp: time.toString(),
                        timelineStart: 1,
                        timelineEnd: 20000000,
                        timelineDensity: 20000,
                        crop: crop,
                        ...options
                    }
                )
                let displayErrorMessage = ""
                if (state.cameras[index].streamInfo.duration?.fromStamp > time) {
                    displayErrorMessage = "Ezen időponton nincs rögzítés!"
                } else {
                    displayErrorMessage = "Felvétel nem elérhető!"
                }
                if (!await isJpeg(data)) {
                    throw displayErrorMessage
                }
                return {id, image: ImageSource.fromData(data)}
            } catch(error){
                if (error.name === "InvalidStateError")
                    throw "Loading..." // TODO instead reverting loading state
                throw error
            }

        },
        async loadBySites({commit, rootGetters}, sites){
            let cams = []
            sites.forEach(site => {
                cams.push(API.getSiteCameras(rootGetters.vvs, site.id))
            });
            const tmp = await Promise.allSettled(cams)
            cams = (tmp.reduce((tmp, x) => [...tmp, ...x.data], []).map(c => ({
                id: c.id,
                site: c.site,
                name: c.name,
            })))
            commit('setCameras', cams)
        }
    }
}

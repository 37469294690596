import { createI18n } from "vue-i18n";
import hu from "./locales/hu.json";
import us from "./locales/us.json";

const messages = { hu, us };

export default createI18n({
  legacy: false,
  locale: "us",
  fallbackLocale: "us",
  messages,
});

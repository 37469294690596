<template>
    <div v-show="selectCamOverlay" ref="cam_search"  class="overlay-popup" @mousedown="selectCamOverlay = false">
        <div class="content" @mousedown.stop>
            {{ $t("control.chooseCam") }}
            <Search :modelValue="allCam" @update:modelValue="updateCameras" :suggestions="cameraSuggestions" />
        </div>
    </div>
    <div v-show="createNewViewOverlay" class="overlay-popup" @mousedown="closeNewView">
        <div class="content new-view-form" @mousedown.stop>
            <label class="label">
                {{ $t("control.name") }}<br/>
                <input
                    v-model="newViewName"
                    label=""
                />
            </label>
            <GridSizeSelector class="grid-selector" :cols="10" :rows="10" v-model="gridSize"/>
            <br/>
            {{ $t("control.gridSize") }} {{ gridSize?.[0] ?? 0}}x{{ gridSize?.[1] ?? 0 }}
            <br/>
            <Button class="btn add" @click="saveNewView">
            {{ $t("buttons.save") }}
            </Button>
            <Button class="btn" @click="closeNewView">
            {{ $t("buttons.cancel") }}
            </Button>
        </div>
    </div>
    <div class="page" ref="page">
        <Header>
            <div class="header-toolbar">
                <MatrixLiveSwitcher :matrix="false" :bodycam="areArchivedCameras" :control="true"/>
                <!-- <Button icon="list" @click="$router.push('/matrix')" /> -->
                <Button icon="fullscreen" @click="toggleFullScreen" />
            </div>
            <div class="search-box" ref="search" >
                <Search ref="searchComponent" :modelValue="tags" @update:modelValue="updateTags" :suggestions="suggestions" />
            </div>
            <Button @click="createNewView" class="add">
                {{ $t("control.new") }}
            </Button>
        </Header>
        <div class="viewport camera-grid-layout" v-show="tags[0]" ref="viewport">
            <CameraGridLayout @selectView="selectView" @editView="addCameraToView" :cameras="camerasLivePlaylists" :rows="gridRows" :cols="gridCols"  />
        </div>
        <div class="message-box" v-show="!tags[0]">
            <span>
                {{ $t("control.empty") }}
            </span>
        </div>
    </div>
</template>

<script>

import {Button, CameraGridLayout} from "@vidarex-vsaas/client-component-library";
import Header from "@/components/Header.vue";
import MatrixLiveSwitcher from "@/components/MatrixLiveSwitcher.vue";
import { mapState } from 'vuex'
// import Logger from "@/utils/logger.js"

// import router from '../router'
import { ROUTE_NAMES} from '../router/routes.js'
import {
    postControlRooms,
    deleteControlRoomsId,
    putControlRoomsIdCellsIndexCamera,
} from '../services/apiv2'
import GridSizeSelector from '@/components/GridSizeSelector.vue'

export default {
    name: "VControl",
    data() {
        return {
            refreshPageIntervalEnabled: false,
            refreshTokenInterval: null,
            refreshPageInterval: null,
            selected: null,
            selectCamOverlay: false,
            createNewViewOverlay: false,
            viewsData: [],
            newViewName: "",
            gridSize: [0,0]
        }
    },
    async created( ){
        await this.$store.dispatch("site/load");
    },
    mounted(){
        const currentView = this.suggestions.find(s => s.value.id === this.viewId)
        if (currentView) {
            this.$store.state.controlTags = [currentView]
        }
        this.refreshTokenInterval = setInterval(() => {
            this.$store.dispatch("account/refreshToken")
        }, 1000*60*30);
        if (this.refreshPageIntervalEnabled) {
            this.refreshPageInterval = setInterval(() => {
                location.reload()
            }, 1000*60*120);
        }
    },
    unmounted(){
        clearInterval(this.refreshTokenInterval)
        clearInterval(this.refreshPageInterval)
    },
    props: ["viewId"],
    components: {
        Button,
        Header,
        CameraGridLayout,
        GridSizeSelector,
        MatrixLiveSwitcher
    },
    computed: {
        ...mapState( {
            // tags: state => [state.control.currentView]
            tags: state => state.controlTags
        }),
        ...mapState('camera',{
            allCam: state => state.tags,
            areArchivedCameras: state => state.areArchivedCameras
        }),
        currentView(){
            return this.$store.state.control.currentView
        },
        gridCols(){
            // if (!this.currentView)
            //     return 4
            // return Math.max(Math.min(this.suggestions.find(x => x.text === this.tags[0].text)?.value.cols ?? 4, 4), 1)
            console.log("this.currentView", this.currentView)
            return this?.currentView?.columnSizes?.length ?? 1
        },
        gridRows(){
            // if (!this.currentView)
            // return 4
            // return Math.max(Math.min(this.suggestions.find(x => x.text === this.tags[0].text)?.value.rows ?? 4, 4), 1)
            return this?.currentView?.rowSizes?.length ?? 1
            // return this.currentView.numberOfRows

        },
        cameras(){
            if (!this.tags[0])
            return []
            return this.currentView.cells.map(x => ({
                camId: x?.camera?.id,
                id: x?.camera?.stream?.id
            }))
            // return this.suggestions.find(x => x.text === this.tags[0].text)?.value.cameras ?? []
        },
        camerasLivePlaylists(){
            console.log(this.cameras.map(x => x?this.getLivePlaylist(x.id):''))
            return this.cameras.map(x => x?this.getLivePlaylist(x.id):'')
        },
        cameraSuggestions(){
            return this.$store.getters.cameras
                    .map(c => c.tags)
                    .flat()
                    .filter(tag => tag.type===0)

                    .sort((a,b) => {
                        if (b.type - a.type === 0){
                            if (a.level === undefined || a.level - b.level === 0){
                                return a?.name?.localeCompare(b?.name)
                            }
                            return a.level - b.level
                        }
                        return b.type - a.type
                    })
                    .map(tag => ({text: tag.name, value: tag}))
                    .reduce((newArr, value) => {
                        if (!newArr.map(a => a.text).includes(value.text))
                            newArr.push(value)
                        return newArr
                    },[])
        },
        suggestions(){
            const cr = this.$store.state.control.controlRooms
            if (!Array.isArray(cr)) return []
            return cr.map(v => ({
                text: v.name,
                value: v,
            }))
        }
    },
    methods: {
        resetNewViewForm(){
            this.newViewName = ""
            this.gridSize = null
        },
        async saveNewView(){
            if (this.newViewName.length <= 0) {
                return alert("Kötelező megadni a nézet nevét!")
            }
            if (this.gridSize[0] <= 0 || this.gridSize[1] <= 0) {
                return alert("Kötelező kiválasztani a rácsméretet")
            }
            try {
                const newView = await postControlRooms({
                    name: this.newViewName,
                    numberOfColumns: this.gridSize[0],
                    numberOfRows: this.gridSize[1],
                })
                await this.$store.dispatch("control/getControlRooms");
                this.$router.push({ name: ROUTE_NAMES.CONTROLVIEW, params:  {viewId: newView.id}})
                this.resetNewViewForm()
                this.createNewViewOverlay = false
            } catch(error){
                alert("Hiba történt!")
            }
        },
        closeNewView() {
            this.createNewViewOverlay = false
            this.resetNewViewForm()
        },
        createNewView(){
            this.createNewViewOverlay = true
            // return router.push({name: ROUTE_NAMES.CONTROLNEWVIEW})
            // const name = prompt("Nézet neve:", '') ?? 'Névtelen'
            // let cols = prompt("Oszlop:", '4') ?? 4
            // let rows = prompt("Sor:", '4') ?? 4
            // if (!Number.isInteger(Number(cols)))
            //     cols = 4
            // if (!Number.isInteger(Number(rows)))
            //     rows = 4
            // if (this.viewsData.findIndex(x => x.name === name) !== -1)
            //     return alert("Ez a név már foglalt! Válassz másikat.")
            //     this.addView({name: name, cameras: [], rows, cols})
            //     this.tags[0] = this.suggestions[this.viewsData.length-1]
        },
        addView(newItem){
            this.viewsData.push(newItem)
            localStorage.setItem('views', JSON.stringify(this.viewsData))
        },
        async deleteView(viewId){
            if(!confirm('Biztosan törölni szeretnéd?')) return
            try {
                await deleteControlRoomsId(viewId)
                this.$store.dispatch("control/getControlRooms");
            } catch(error){
                alert('Hiba történt!')
            }
        },
        async updateTags (e) {
            if (e.length === 0){
                // DELETE
                await this.deleteView(this.tags[0].value.id)
                const remainingSuggestions = this.suggestions.filter(x => x.value.id !== this.tags[0].value.id)
                if (remainingSuggestions.length){
                    const selectedView = remainingSuggestions[remainingSuggestions.length-1] ?? null
                    this.$router.push({ name: ROUTE_NAMES.CONTROLVIEW, params:  {viewId: selectedView.value.id} })
                    // this.$store.state.controlTags = [this.suggestions[this.suggestions.length-1]] ?? null
                }
                else
                this.$store.state.controlTags = []
                return
            } else {
                // SELECT
                const selectedTag = e.pop()
                if (selectedTag) {
                    const selectedView = selectedTag.value
                    this.$router.push({ name: ROUTE_NAMES.CONTROLVIEW, params:  {viewId: selectedView.id} })
                }
            }
            this.$refs.searchComponent.inputBlur()
        },

        async updateCameras (e) {

            if (e.length){
                // this.cameras[this.selected] = (
                //     await this.$store.dispatch('camera/getLivePlaylist', {camera: this.$store.state.camera.cameras.find(c => c.name === e[0].text), token: this.$store.state.account.token})
                // )
                // console.log(this.$store.state.camera.cameras)
                // debugger //eslint-disable-line

                await putControlRoomsIdCellsIndexCamera(this.currentView.id, this.selected, this.$store.state.camera.cameras.find(c => c.name === e[0].text).deviceId)
                this.$store.dispatch("control/getControlRoomsId", this.viewId);
                // this.cameras[this.selected] = this.$store.state.camera.cameras.find(c => c.name === e[0].text)
                // localStorage.setItem('views', JSON.stringify(this.viewsData))
                // this.selectView(null)
                // this.$refs.viewport.querySelector(".viewport .active").classList.remove("active")
                this.selectCamOverlay = false
                // localStorage.setItem('views', this.viewsData)
            }

        },

        // getLivePlaylist(id) {
        //     // const active = this.$store.getters.cameras.find((c) => c.id === this.id);
        //     const active = this.$store.getters.cameras[id]
        //     return`${active.connections.http}api/playlist/${active.id}/playlist.m3u8?token=` + this.$store.state.account.token
        // },
        getLivePlaylist(id) {
            const active = this.$store.getters.cameras.find((c) =>
                c.streams.find(s => s.id === id)
            );
            if (active)
                return`${active.connections.http}api/playlist/${id}/playlist.m3u8?token=` + this.$store.state.account.token
            return ''
        },
        toggleFullScreen(){
            this.$refs.viewport.requestFullscreen()
        },
        selectView(index){
            const activeCam = this.$store.getters.cameras.find(c => c.deviceId === this.cameras[index].camId)
            if (activeCam && activeCam.streams[0].id)
            this.$router.push('/camera/' + activeCam.streams[0].id)
            else
            this.addCameraToView(index)
        },
        addCameraToView(index){
            this.selectCamOverlay = true
            // if (this.$refs.viewport.querySelector(".viewport .active"))
            //     this.$refs.viewport.querySelector(".viewport .active").classList.remove("active")
            // if (index === null)
            //     return this.selected= null
            this.selected = index

            //TODO: add setFocus capability to the component
            setTimeout(()=>{
                this.$refs.cam_search.querySelector("input").focus()
            },0)
            // this.$refs.viewport.querySelectorAll(".viewport")[this.selected].classList.add("active")
        }
    },
    watch: {
        viewId(){
            this.$store.state.controlTags = [this.suggestions.find(s => s.value.id === this.viewId)]
            this.$store.dispatch("control/getControlRoomsId", this.viewId);
            console.log("viewId watch", this.$store.state.controlTags, this.viewId)

        }
    }
};
</script>

<style scoped lang="scss">
@import "@/theme/vars.scss";
.message-box {
    align-self: center;
    margin: auto;
    padding: 40px;
}

.search-box {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.new-view-form .btn.add {
    margin: 12px 0;
}

.viewport {
    width: 100%;
}
.header-toolbar {
    margin-left: 20px;
}
.viewport::v-deep .active {
    border-color: #00318d;
}
.page {
    box-sizing: border-box;
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
    padding-top: $header-size;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    &::after {
        height: 1px;
        margin-bottom: -1px;
        content: "";
        display: block;
    }
}

.menu-top {
    font-size: 14px;
    font-weight: 700;
    margin-left: auto;
}

.container {
    max-width: $container-width;
    margin: auto;
}

.video-screen {
    width: 100%;
    height: 60vh;
    position: absolute;
    overflow: hidden;
    z-index: 0;
}

.controls {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    margin: auto auto 0;
}

.controls-group {
    display: flex;
    align-items: center;
    &:first-child,
    &:last-child {
        flex: 1;
    }
    &:last-child {
        justify-content: end;
    }
}

.sign-out {
    color: #00a0b6;
    font-weight: 900;
    margin-left: 10px;
}

.section-player {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    height: calc(100vh - 0px);
    padding: 0 40px;
}

.video-player {
    width: 100%;
    height: calc(100vh - 40px);
    height: calc(100vh - 0px);
    margin-top: $header-size * -1;
    padding-top: $header-size;
    box-sizing: border-box;
}

.icon {
    width: 24px;
    height: 24px;
}
#my-video {
    width: 100%;
    height: 100%;
}
.section-matrix {
    background-color: #222222;
    padding: 40px;
}
.add {
    background-color: #222;
}

.overlay {
    color: white;
    background-color: #000000cc;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
}
.overlay-popup {
    background-color: #88888866;
    color: white;
    z-index: 1000;
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .content {
        padding: 20px;
        background-color: #000000;
        max-width: 420px;
        margin: auto 0;
    }
}

.camera-grid-layout {
    cursor: none
}
</style>

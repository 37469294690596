import {v4 as uuidv4} from "uuid"

export default {
    install: (app, options) => {
      app.config.globalProperties.$translate = () => {
        return 2
      }
  
      app.provide('i18n', options)
  
      app.mixin({
        created() {
          this.$uuid = uuidv4()
        }

      })
    }
  }
<template>
    <Field :name="name" v-slot="{ handleChange, value, errorMessage, setTouched }">
        <Input
            :modelValue="value"
            :placeholder="placeholder"
            :disabled="disabled"
            @update:modelValue="handleChange($event)"
            @blur="setTouched(true)"
            :type="type"
            :label="label"
            :info="errorMessage"
            :infoType="errorMessage && 'error'"
        />
    </Field>
</template>

<script>
import Input from "@/components/Input.vue";
import { Field } from "vee-validate";

export default {
    name: "VInput",
    props: {
        name: String,
        label: String,
        type: String,
        placeholder: String,
        disabled: {
            default: false,
            type: Boolean
        },
    },
    components: {
        Input,
        Field
    }
}
</script>

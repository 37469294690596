import Logger from "@/utils/logger.js"
// function wsHandlerAsync(...args) {
//     const self = {}
//     const retryTimeout = 2000
//     let connection = null
//     let retryCounter = 0
//     let listeners = []

//     function createConnection(){
//         return new Promise((resolve, reject) => {
//             connection = new WebSocket(...args)
//             self.connection = connection
//             connection.addEventListener("open", () => {
//                 retryCounter = 0
//                 listeners.forEach(({fn, type}) => {
//                     connection.addEventListener(type, fn)
//                 })
//                 Logger.log("Websocket connection opened!", connection);
//                 resolve(self)
//             })
    
//             connection.addEventListener("error", (event) => {
//                 Logger.log("Websocket connection error!", event);
//                 reject(self)
//             })
                
//             connection.addEventListener("close", (event) => {
//                 Logger.log("Websocket connection closed!", event);
//                 if (event.code !== 1000 && retryCounter++ < 3) setTimeout(createConnection, retryTimeout)
//             });
//         })
//     }

//     // TODO: finish it
//     self.removeEventListener = (type, fn) => {
//         connection.removeEventListener(type, fn)
//     }
    
//     self.addEventListener = (type, fn) => {
//         listeners.push({type, fn})
//         if (connection.readyState === WebSocket.OPEN) {
//             connection.addEventListener(type, fn)
//         }
//     }

//     self.send = (...args) => {
//         connection.send(...args)
//     }

//     return createConnection()
// }

function wsHandler(...args) {
    const self = {}
    const retryTimeout = 3000
    let connection = null
    let retryCounter = 0
    let listeners = []

    function createConnection(){
        return new Promise((resolve, reject) => {
            connection = new WebSocket(...args)
            self.connection = connection
            connection.addEventListener("open", () => {
                retryCounter = 0
                listeners.forEach(({fn, type}) => {
                    connection.addEventListener(type, fn)
                })
                Logger.log("Websocket connection opened!", connection);
                resolve(self)
            })
    
            connection.addEventListener("error", (event) => {
                Logger.log("Websocket connection error!", event);
                reject(self)
            })
                
            connection.addEventListener("close", (event) => {
                Logger.log("Websocket connection closed!", event);
                if (event.code !== 1000 && retryCounter++ < 3) setTimeout(createConnection, retryTimeout)
            });
        })
    }

    // TODO: finish it
    self.removeEventListener = (type, fn) => {
        connection.removeEventListener(type, fn)
    }
    
    self.addEventListener = (type, fn) => {
        listeners.push({type, fn})
        if (connection.readyState === WebSocket.OPEN) {
            connection.addEventListener(type, fn)
        }
    }

    self.send = (...args) => {
        if (connection.readyState === WebSocket.OPEN){
            connection.send(...args)
        } else if (connection.readyState === WebSocket.CLOSED){
            createConnection()
        }
    }

    self.reconnect = createConnection()

    return self
}

function wsManager(...args){
    let requestId = 1
    let ws = wsHandler(...args)
    let pendingRequests = []

    ws.addEventListener("message", async (response)=>{
        if (typeof response.data === "object") {
            let resId;

            const dataBuffer = await response.data.arrayBuffer();

            const data = new DataView(dataBuffer);
            if (dataBuffer.byteLength < 4) return Logger.log("malformed message");
            resId = data.getInt32(0, false);

            pendingRequests[resId].resolve({
                id: resId,
                data: dataBuffer
            })
            pendingRequests[resId] = null
        }
    })

    return {
        TIMEOUT: 0,
        close(code){
            ws.connection.close(code)
        },
        async send(data, timeout = 1000){
            data.id = requestId++
            return new Promise((resolve, reject)=> {
                ws.send(JSON.stringify(data))
                pendingRequests[data.id] = {resolve, reject}
                setTimeout(() => pendingRequests[data.id] && pendingRequests[data.id].reject({requestId: data.id, type: this.TIMEOUT}), timeout)
            })
        }
    }
}

// const w = wsHandler("wss://vvs2.vidarex.com:8999/")
// w.then(w => 
//     w.send(
//         JSON.stringify({"cameraId":"BAYER_CAM_16","action":"image","timestamp":1649324113652,"timelineStart":1,"timelineEnd":20000000,"timelineDensity":20000000,"id":11})
//     )
// );

// function wsManager(urls, { console: console, adapter: Websocket }){

//     this.connect = (url, i) => new Promise((resolve, reject)=>{
//         const conn = new adapter(url)

//         conn.addEventListener("open", () => {
//             Logger.log("Websocket connection opened!", conn);
//             resolve(conn)
//         })

//         conn.addEventListener("error", (event) => {
//             Logger.log("Websocket connection error!", event);
//             reject(conn)
//         })
            
//         conn.addEventListener("close", (event) => {
//             Logger.log("Websocket connection closed!", event);
//             if (event.code !== 1000) this.connections[i] = this.connect(url, i);
//         });
//     })
//     this.connections = urls.map(this.connect)

//     this.send = async (conn, msg)=>{
//         (await conn).send(msg)
//     }

//     return this
// }
// wsManager
export default wsManager
    

// export default class {
//     constructor(urls){
//         let readyCounter = 0
//         this.connections = urls.map((url)=>this.connect(url, (conn)=>{
//             readyCb(conn, url)
//             if (++readyCounter === urls.length)
//                 everyConnectionReadyCb()
//         }))
//     }


//     connect(url, openCb = ()=>{}){
//         const conn = new WebSocket(url)

//         conn.addEventListener("open", () => {
//             Logger.log("Websocket connection opened!", conn);
//             openCb(conn)
//         })
            
//         conn.addEventListener("close", (event) => {
//             Logger.log("Websocket connection closed!", event);
//             if (event.code !== 1000) return this.reconnect(conn, openCb);
//         });
        
//         return conn
//     }
//     getConnectionByURL(url){
//         return this.connections.find(c => c.url.indexOf(url) === 0)
//     }
//     getConnectionIndexByRef(conn){
//         return this.connections.findIndex(c => c === conn)
//     }
//     reconnect(conn, openCb){
        
//         this.connections[this.getConnectionIndexByRef(conn)] = this.connect(conn.url, openCb)
//     }
//     closeAll(code=1000){
//         this.connections.forEach(conn => conn.close(code))
//     }
// }
import Logger from "@/utils/logger.js"

const TEST_DELAY_MS = 500
const TIMEOUT_MS = 10000
let autoIncrement = 1

export const WEBSOCKET_STATES = {
    CONNECTING: WebSocket.CONNECTING,
    OPEN: WebSocket.OPEN,
    CLOSING: WebSocket.CLOSING,
    CLOSED: WebSocket.CLOSED,
}

export default class Websocket extends EventTarget {
    constructor(url){
        super()
        this.url = url
        this.open()
        // this.connection = this.createConnection()
        // this.messageResolve = []
        // this.messageReject = []

        // this.connection.addEventListener('open', (event) => {
        //     this.connectedResolve()
        //     this.connectionPending = false
        //     Logger.log("Websocket opened!", event)
        // })

        // this.connection.addEventListener('error', (event) => {
        //     if (this.connectionPending == true){
        //         this.connectedReject()
        //         this.connectionPending = false
        //     }
        //     Logger.log("Websocket error happened, closing socket!", event)
        //     this.close()
        // })
        // this.connection.addEventListener('close', () => {
        //     this.connectionPending = false
        //     Logger.log("Websocket connection closed!")
        // })

        // this.connection.addEventListener('message', async ({data: buffer}) => {
        //     Logger.log("WS something arrived")
        //     const {id, data} = await this.parseMessage(buffer)
        //     if (this.messageResolve[id]) // if async send
        //         this.messageResolve[id]({data, id})
        //     this.clearTimeouts()
        // })
        // return connection
    }
    addEventListener(){
        super.addEventListener(arguments)
    }
    createConnection(){
        const connection = new WebSocket(this.url)

        this.messageResolve = []
        this.messageReject = []
        this.messageRejectTimeout = []

        connection.addEventListener('open', (event) => {
            if (this.connectedResolve) this.connectedResolve()
            this.connectionPending = false
            Logger.log("Websocket opened!", event)
        })

        connection.addEventListener('error', (event) => {
            if (this.connectionPending == true){
                this.connectedReject()
                this.connectionPending = false
            }
            Logger.log("Websocket error happened, closing socket!", event)
            this.connection.close()
        })
        connection.addEventListener('close', () => {
            this.connectionPending = false
            //FIXME: keepalive = true
            //this.open()
            this.messageReject.map(r => r())
            Logger.log("Websocket connection closed!")
        })

        connection.addEventListener('message', async ({data: buffer}) => {
            Logger.log("WS something arrived")
            const resp = await this.parseMessage(buffer)
            if (this.messageResolve[resp?.id]){
                clearTimeout(this.messageRejectTimeout[resp.id])
                this.messageResolve[resp.id](resp)
            }
            this.clearTimeouts()
        })
        return connection
    }
    parseMessage(){
        // Interface
        throw Error("ParseMessage not implemented!")
    }
    open(){
        if (this.state() === WEBSOCKET_STATES.CONNECTING) return this.connectionPromise
        if (this.state() === WEBSOCKET_STATES.OPEN) return this.connectionPromise
        this.connectionPromise = new Promise((resolve, reject) => {
            this.connectionPending = true
            this.connectedResolve = resolve
            this.connectedReject = reject
            this.connection = this.createConnection()
        })
        return this.connectionPromise
    }
    sendAsync(data){
        return new Promise((resolve, reject) => {
            if (this.state() === WEBSOCKET_STATES.CLOSED || this.state() === WEBSOCKET_STATES.CLOSING){
                this.open()
                return reject("Connection error!")
            }
            if (this.state() !== WEBSOCKET_STATES.OPEN && this.state() !== WEBSOCKET_STATES.CONNECTING){

                    Logger.log("Websocket connection state not OPEN", this.state())
                    return reject("Connection bad state!")

            }

            if (!data) data = {}

            if (!data.id)
                data.id = autoIncrement++

            this.clearTimeouts()
            this.connectionTimeout = setTimeout(() => this.testConnection(), TEST_DELAY_MS)

            this.messageResolve[data.id] = resolve
            this.messageReject[data.id] = reject
            const messageId = data.id
            this.messageRejectTimeout[data.id] = setTimeout(() => {
                if (this.messageReject[messageId])
                    this.messageReject[messageId]("Request timed out!")
            }, TIMEOUT_MS)
            if (this.state() === WEBSOCKET_STATES.CONNECTING){
                this.connectionPromise.then(() => {
                    this.connection.send(JSON.stringify(data))

                }).catch(error => reject(error))
                return false
            }
            this.connection.send(JSON.stringify(data))
        })
    }
    state(){
        if (this.connection)
        return this.connection.readyState
        return WEBSOCKET_STATES.CLOSED
    }
    send(data, noTimeout = false){
        // Not used
        if (!data) data = {}

        if (this.state() !== WEBSOCKET_STATES.OPEN){
            if (this.state() !== WEBSOCKET_STATES.CONNECTING)
                this.open()
            Logger.log("Websocket connection state not OPEN", this.state())
            return false
        }
        if (!data.id)
            data.id = autoIncrement++

        if (!noTimeout){
            this.clearTimeouts()
            this.connectionTimeout = setTimeout(() => this.testConnection(), TEST_DELAY_MS)
        }
        this.connection.send(JSON.stringify(data))
        return data.id
    }

    clearTimeouts(){
        if (this.connectionTimeout)
            clearTimeout(this.connectionTimeout)
        if (this.testConnectionTimeout)
            clearTimeout(this.testConnectionTimeout)
    }

    testConnection(){
        if (this.connection.state !== WEBSOCKET_STATES.OPEN) return
        Logger.log("Websocket connection still alive?")
        this.testConnectionTimeout = setTimeout(()=>{
            Logger.log("Websocket answer not received, reopen connection")
            this.connection.close()
            this.open()
        }, TIMEOUT_MS)

        this.connection.send(JSON.stringify({
            id: 0,
            cameraId: 'TEST',
            action: 'image',
            timestamp: '0',
            timelineStart: 1,
            timelineEnd: 2,
            timelineDensity: 0
        }))
    }
}
